import axios from "axios";
import decode from "jwt-decode";
let baseAPIURL = "";
export let baseCEXURL = "";
export let baseNewCEXURL = "";
export let baseDEXURL = "";
export let baseURL = "";

export let baseHiveURL = "";
export let baseWSURL = "";
export let baseWalletURL = "";
export let baseShopURL = "";
export let baseXnftURL = "";
export let baseMktplaceURL = "";
export let baseAcademyUrl = "";
export let baseLottoUrl = "";
export let baseIndexxShopUrl = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
  baseAPIURL = "https://api.indexx.ai";
  baseCEXURL = "https://cex.indexx.ai";
  //baseCEXURL = "http://localhost:3001/";
  baseNewCEXURL = "https://cex.indexx.ai/update/home";
  //baseNewCEXURL = "http://localhost:3000/update/home";
  baseDEXURL = "https://dex.indexx.ai";
  baseURL = "https://indexx.ai";
  //baseAPIURL = "http://localhost:5000";
  baseLottoUrl = "https://lotto.indexx.ai";
  //baseLottoUrl = "http://localhost:3000";
  baseHiveURL = "https://hive.indexx.ai";
  baseWSURL = "https://wallstreet.indexx.ai";
  baseWalletURL = "https://wallet.indexx.ai";
  baseShopURL = "https://shop.indexx.ai";
  baseXnftURL = "https://xnft.indexx.ai";
  baseMktplaceURL = "https://xnftmarketplace.indexx.ai";
  baseAcademyUrl = "https://academy.indexx.ai";
  baseIndexxShopUrl = "https://shop.indexx.ai/";
} else {
  baseCEXURL = "https://cex.indexx.ai";
  baseNewCEXURL = "https://cex.indexx.ai/update/home";
  baseDEXURL = "https://dex.indexx.ai";
  baseAPIURL = "https://api.indexx.ai";
  baseURL = "https://indexx.ai";
  baseLottoUrl = "https://lotto.indexx.ai";
  baseHiveURL = "https://hive.indexx.ai";
  baseWSURL = "https://wallstreet.indexx.ai";
  baseWalletURL = "https://wallet.indexx.ai";
  baseShopURL = "https://shop.indexx.ai";
  baseXnftURL = "https://xnft.indexx.ai";
  baseMktplaceURL = "https://xnftmarketplace.indexx.ai";
  baseAcademyUrl = "https://academy.indexx.ai";
  baseIndexxShopUrl = "https://shop.indexx.ai/";
}
//console.log("baseURL", baseURL);

const API = axios.create({
  baseURL: baseAPIURL,
});

export default baseAPIURL;

export const signupAPI = async (
  email: string,
  password: string,
  referralCode: string
) => {
  try {
    const result = await API.post("/api/v1/inex/user/register", {
      email,
      password,
      referralCode,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (signupAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const loginWithToken = async (signInToken: any) => {
  try {
    const result = await API.post("/api/v1/inex/user/loginWithToken", {
      signInToken,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginWithToken)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const loginAPI = async (email: string, password: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/login", {
      email,
      password,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const checkemail = async (email: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/checkByemail", {
      email,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const checkEmail = async (email: string) => {
  try {
    email = email.toLocaleLowerCase();
    const result = await API.post("/api/v1/inex/user/checkemail", {
      email,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const getCaptainBeeStatics = async (
  username: string,
  isPublicProfile: string = "no"
) => {
  try {
    const result = await API.get(
      `/api/v1/affiliate/getAffiliateUserDashbaord/${username}/${isPublicProfile}`
    );
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const getHoneyUserDetails = async (email: string) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getHoneyUserDetails/${email}`
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getHoneyUserDetails)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getCaptainBeeByEmail = async (email: string) => {
  try {
    const result = await API.get(
      `/api/v1/affiliate/getAllaffiliateUser/${email}`
    );
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const getUserShortToken = async (email: string) => {
  try {
    const result = await API.get(`/api/v1/inex/user/createShortToken/${email}`);
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createShortToken)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const loginHive = async (email: string, password: string) => {
  try {
    let isEmailProvided = isEmail(email);
    if (isEmailProvided) {
      const result = await API.post("/api/v1/inex/user/hivelogin", {
        email,
        password,
      });
      return result.data;
    } else {
      const result = await API.post("/api/v1/inex/user/hivelogin", {
        username: email,
        password,
      });
      return result.data;
    }
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

function isEmail(input: string) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailPattern.test(input);
}

const userInput = "exampleUserInput"; // Replace with the actual user input

if (isEmail(userInput)) {
  console.log("The user provided an email.");
} else {
  console.log("The user provided a username.");
}

export const sendForgotPasswordOtp = async (email: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/sendForgotOtp", {
      email,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const loginWithGoogle = async (tokenResponse: string) => {
  try {
    const result = await API.post(`/api/v1/inex/user/login/google`, {
      googleToken: tokenResponse,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getUserDetails)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const signupWithGoogle = async (tokenResponse: string) => {
  try {
    const result = await API.post(`/api/v1/inex/user/register/google`, {
      googleToken: tokenResponse,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getUserDetails)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const sendOtp = async (email: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/sendOtp", {
      email,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const validateOtp = async (email: string, code: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/validateOtp", {
      email,
      code,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const validateForgotOtp = async (email: string, code: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/validateForgotOtp", {
      email,
      code,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const checkByemail = async (email: string) => {
  try {
    email = email.toLocaleLowerCase();
    const result = await API.post("/api/v1/inex/user/checkByemail", {
      email,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const checkByWebWalletEmail = async (email: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/checkByWebWalletEmail", {
      email,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const airdropRegister = async (
  email: string,
  userType: string,
  walletAddress: string,
  walletProvider: string,
  airdropAmount: number,
  tokenName: string,
  eventType: string = ""
) => {
  try {
    const result = await API.post("/api/v1/inex/basic/registerairdrop", {
      email,
      userType,
      walletAddress,
      walletProvider,
      airdropAmount,
      tokenName,
      eventType,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const bitcoinAirdropRegister = async (
  email: string,
  userType: string,
  walletAddress: string,
  walletProvider: string,
  airdropAmount: number,
  tokenName: string,
  eventType: string = ""
) => {
  try {
    const result = await API.post("/api/v1/inex/basic/registerbitcoinairdrop", {
      email,
      userType,
      walletAddress,
      walletProvider,
      airdropAmount,
      tokenName,
      eventType,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const wisbAirdropRegister = async (
  email: string,
  userType: string,
  walletAddress: string,
  walletProvider: string,
  airdropAmount: number,
  tokenName: string,
  eventType: string = ""
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/basic/registerwibsjun16airdrop",
      {
        email,
        userType,
        walletAddress,
        walletProvider,
        airdropAmount,
        tokenName,
        eventType,
      }
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (loginAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const dacrazytAirdropRegister = async (
  email: string,
  userType: string,
  walletAddress: string,
  walletProvider: string,
  airdropAmount: number,
  tokenName: string,
  eventType: string = ""
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/basic/registerdacrazyairdrop",
      {
        email,
        userType,
        walletAddress,
        walletProvider,
        airdropAmount,
        tokenName,
        eventType,
      }
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (dacrazytAirdropRegister)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const logoutAPI = async () => {};

export const getCountries = async () => {
  try {
    const result = await API.post("/api/v1/inex/getCountries");
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getCountriesAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getUserWallet = async () => {};

export const verifyPhoneCode = async (code: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/verifyPhoneCode", {
      code,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const getWalletBalance = async (email: string, coin: string) => {
  try {
    const result = await API.post(
      `/api/v1/inex/user/getBalance/${email}/${coin}`
    );
    if (result.status === 200) return result.data;
    else return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const getIndexxTokenPrices = async () => {
  try {
    const result = await API.post("/api/v1/inex/basic/indexxcoins");
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

export const getCryptoPrice = async (coin: string) => {
  try {
    const result = await API.post(`api/v1/inex/basic/getPriceByName`, {
      coin: coin,
    });
    return result.data;
  } catch (e: any) {
    return e.response.data;
  }
};

//https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=USD&days=1&interval=hourly
export const getGraphicalCurrencyData = async (
  coinId: string,
  days: string,
  currency: string = "USD"
) => {
  let url = "";
  if (coinId === "IUSD+") {
    url = `https://api.coingecko.com/api/v3/coins/tether/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "INXC") {
    url = `https://api.coingecko.com/api/v3/coins/uma/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "IN500") {
    url = `https://api.coingecko.com/api/v3/coins/spdr-s-p-500-etf-trust-defichain/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BTC") {
    url = `https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BNB") {
    url = `https://api.coingecko.com/api/v3/coins/binancecoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "ETH") {
    url = `https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "LTC") {
    url = `https://api.coingecko.com/api/v3/coins/litecoin/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "BUSD") {
    url = `https://api.coingecko.com/api/v3/coins/binance-usd/market_chart?vs_currency=${currency}&days=${days}`;
  } else if (coinId === "INEX") {
    url = `https://api.coingecko.com/api/v3/coins/stellar/market_chart?vs_currency=${currency}&days=${days}`;
  }
  let res = await fetch(url);
  console.log(res);
  return res;
};

export function isLoggedIn() {
  return !!getJwtToken();
}

export function getJwtToken() {
  return localStorage.getItem("access_token");
}

export function getRefreshToken() {
  var token = localStorage.getItem("refresh_token");
  return token;
}

export function storeTokens(tokens: TokenLite) {
  //exact jwt and store in local store as user object
  localStorage.setItem("access_token", tokens.access_token);
  localStorage.setItem("refreshToken", tokens.refresh_token);
}

export function removeTokens() {
  //remove from local store the user object
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

export interface TokenLite {
  access_token: string;
  refresh_token: string;
}

export const getAllCountries = async (email: string, coin: string) => {
  try {
    const result = await API.post("/api/v1/inex/getCountries");
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getCountriesAPI)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export function decodeJWT(access_token: string) {
  let userObj = decode(access_token);
  return userObj;
}

export const getUserWallets = async (email: string) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getUserWallets/${email}`);
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getUserWallets)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const checkAndUpdateDeposit = async (
  email: string,
  txHash: string,
  coin: string
) => {
  try {
    const result = await API.post(`/api/v1/inex/transaction/createTx`, {
      email,
      txHash,
      coin,
    });
    return result.data;
  } catch (e: any) {
    console.log(
      "FAILED: unable to perform API request (checkAndUpdateDeposit)"
    );
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

// export const createBuyOrder = async (basecoin: string, quotecoin: string, amount: number, price: number, email: string) => {
//   try {
//     const result = await API.post("/api/v1/inex/order/buy", {
//       basecoin,
//       quotecoin,
//       amount,
//       price,
//       email
//     });
//     return result.data;
//   } catch (e: any) {
//     console.log("FAILED: unable to perform API request (createBuyOrder)");
//     console.log(e);
//     console.log(e.response.data);
//     return e.response.data;
//   }
// }

export const createSellOrder1 = async (
  basecoin: string,
  quotecoin: string,
  amount: number,
  price: number,
  email: string
) => {
  try {
    const result = await API.post("/api/v1/inex/order/sell", {
      basecoin,
      quotecoin,
      amount,
      price,
      email,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createBuyOrder)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const createConvertOrder1 = async (
  basecoin: string,
  quotecoin: string,
  amount: number,
  price: number,
  email: string
) => {
  try {
    const result = await API.post("/api/v1/inex/order/convert", {
      basecoin,
      quotecoin,
      amount,
      price,
      email,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createBuyOrder)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getAllTransactions = async (email: string) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getTransactions/${email}`);
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getTransactions)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getUserRewardDetails = async (email: string) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getUserRewardDetails/${email}`
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getUserRewards)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const updateRewardsWallet = async (
  email: string,
  walletAddr: string
) => {
  try {
    const result = await API.post(`/api/v1/inex/user/updateRewardsWallet`, {
      email: email,
      rewardWalletAddress: walletAddr,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (updateRewardsWallet)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const changePassword = async (
  email: string,
  newPassword: string,
  oldPassword: string
) => {
  try {
    const result = await API.post(`/api/v1/inex/user/changePassword`, {
      email: email,
      newPassword: newPassword,
      oldPassword: oldPassword,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (changePassword)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const result = await API.post(`/api/v1/inex/user/forgotPassword`, {
      email: email,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (forgotPassword)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const resetPassword = async (email: string, password: string) => {
  try {
    const result = await API.post(`/api/v1/inex/user/resetPassword`, {
      email: email,
      password: password,
      code: "123",
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (resetPassword)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getUserDetails = async (email: string) => {
  try {
    const result = await API.post(`/api/v1/inex/user/getUserDetails/${email}`);
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getUserDetails)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getCoinPriceByName = async (
  coin: string,
  type: string = "Buy"
) => {
  try {
    const result = await API.post(`/api/v1/inex/basic/getcoinprice/${coin}`, {
      type: type,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getCoinPriceByName)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getAppSettings = async () => {
  try {
    const result = await API.post("/api/v1/inex/basic/appSettings");
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (appSettings)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const marketsData = async () => {
  try {
    const result = await API.get("/api/v1/inex/basic/marketPrice");
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (marketPrice)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const updateFavCurrencies = async (email: string, currency: string) => {
  try {
    const result = await API.post("/api/v1/inex/basic/updateFavCurrencies", {
      email: email,
      currency: currency,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (updateFavCurrencies)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const createBuyOrder = async (
  basecoin: string,
  quotecoin: string,
  amount: number,
  price?: number
) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: basecoin,
      currencyIn: quotecoin,
      amount: amount,
      price: price,
      orderType: "Buy",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createOrder)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const createSellOrder = async (
  basecoin: string,
  quotecoin: string,
  amount: number,
  price?: number
) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: quotecoin,
      currencyIn: basecoin,
      amount: amount,
      price: price,
      orderType: "Sell",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createOrder)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const confirmSellOrder = async (
  emal: string,
  orderId: string,
  orderStatus: string,
  basecoin: string
) => {
  try {
    const result = await API.post("/api/v1/inex/order/updateOrder", {
      email: emal,
      orderId: orderId,
      orderStatus: orderStatus,
      currencyIn: basecoin,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createOrder)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const createConvertOrder = async (
  basecoin: string,
  quotecoin: string,
  amount: number,
  price?: number
) => {
  try {
    const result = await API.post("/api/v1/inex/order/createOrder", {
      currencyOut: quotecoin,
      currencyIn: basecoin,
      amount: amount,
      price: price,
      orderType: "Convert",
      email: localStorage.getItem("user"),
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createOrder)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const confirmConvertOrder = async (
  emal: string,
  orderId: string,
  orderStatus: string = "Completed",
  basecoin: string = "IN500"
) => {
  try {
    const result = await API.post("/api/v1/inex/order/processCovert", {
      email: emal,
      orderId: orderId,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createOrder)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const createStripePaymentIntent = async (
  amount: number,
  orderId: string,
  email: string
) => {
  try {
    const result = await API.post("/api/v1/inex/stripe/createPaymentIntent", {
      amount: amount,
      orderId: orderId,
      email: email,
    });
    return result.data;
  } catch (e: any) {
    console.log(
      "FAILED: unable to perform API request (createStripePaymentIntent)"
    );
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const oneUSDHelper = async (coinValue: number, coinType: string) => {
  try {
    let oneUSDValue = 0;
    if (coinType === "IN500") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "INXC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "IUSD+") {
      oneUSDValue = 1;
    } else if (coinType === "BUSD") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "BTC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "ETH") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "BNB") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "LTC") {
      oneUSDValue = 1 / coinValue;
    } else if (coinType === "INEX") {
      oneUSDValue = 1 / coinValue;
    } else {
      oneUSDValue = 0.1;
    }
    return oneUSDValue;
  } catch (err) {
    return 0;
  }
};

export const getMinAndMaxOrderValues = async (
  coin: string,
  orderType: string
) => {
  try {
    const result = await API.post("/api/v1/inex/basic/orderMinMax", {
      currency: coin,
      orderType: orderType,
    });
    return result.data;
  } catch (e: any) {
    console.log(
      "FAILED: unable to perform API request (createStripePaymentIntent)"
    );
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const withdrawINEX = async (email: string, amount: number) => {
  try {
    const result = await API.post("/api/v1/inex/user/withdrawRewards", {
      email: email,
      amount: amount,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (withdrawINEX)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const transactionList = async (email: string, type: string = "FIAT") => {
  try {
    const result = await API.post(
      `/api/v1/inex/user/getTransactions/${email}`,
      {
        type: type,
      }
    );
    return result.data;
  } catch (err: any) {
    console.log("FAILED: unable to perform API request (transactionList)");
    console.log(err);
    console.log(err.response.data);
    return err.response.data;
  }
};

export const createFiatWithdraw = async (
  email: string,
  amount: number,
  accountDetails: any,
  coin: string = "USD"
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/transaction/createFiatWithdraw",
      {
        email: email,
        amount: amount,
        accountDetails: accountDetails,
        coin: coin,
      }
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createFiatWithdraw)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const createCryptoWithdraw = async (
  email: string,
  amount: number,
  address: string,
  coin: string = "USD"
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/transaction/createCryptoWithdraw",
      {
        email: email,
        amount: amount,
        address: address,
        coin: coin,
      }
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createCryptoWithdraw)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const createFiatDeposit = async (
  email: string,
  amount: number,
  txHash: any,
  coin: string = "USD"
) => {
  try {
    const result = await API.post(
      "/api/v1/inex/transaction/createFiatDeposit",
      {
        email: email,
        amount: amount,
        txHash: txHash,
        coin: coin,
      }
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (createFiatWithdraw)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const resendEmailCode = async (email: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/resendEmailCode", {
      email: email,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (resendEmailCode)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const validateEmail = async (email: string, code: string) => {
  try {
    const result = await API.post("/api/v1/inex/user/validateEmail", {
      email: email,
      code: code,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (validateEmail)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getOrderDetails = async (email: string, orderId: string) => {
  try {
    const result = await API.get(
      `/api/v1/inex/user/getUserOrder/${email}/${orderId}`
    );
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getOrderDetails)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const adminLogin = async (email: string, password: string) => {
  try {
    const result = await API.post("/api/v1/inex/admin/login", {
      email: email,
      password: password,
    });
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (adminLogin)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};

export const getIndexxMediumBlogs = async () => {
  try {
    const result = await API.get("/api/v1/inex/basic/indexxBlogs");
    return result.data;
  } catch (e: any) {
    console.log("FAILED: unable to perform API request (getIndexxMediumBlogs)");
    console.log(e);
    console.log(e.response.data);
    return e.response.data;
  }
};


export const getMediumPost = async () => {
  try {
      const result = await API.get(
          `/api/v1/inex/admin/getMediumPost`
      );
      return result.data;
  } catch (e:any) {
      console.log('FAILED: unable to perform API request (getMediumPost)');
      console.log(e);
      console.log(e.response.data);
      return e.response.data;
  }
};
