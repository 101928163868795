import { useTheme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "20",
    marginTop: "100px",
    [theme.breakpoints.down("md")]: {
      padding: "10",
    },
  },
}));

const PrivacyPolicyPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>{/* Back to Community Section */}</div>
  );
};

export default PrivacyPolicyPage;
