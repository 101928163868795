import React from "react";
import { Grid, Container } from "@mui/material";
import CardComponent from "./CardComponent";

const CardGrid = ({ posts, showEllipses }) => {
  return (
    <Container>
      <Grid container spacing={5}>
        {posts?.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.id}>
            <CardComponent
              image={post.imageUrl}
              title={post.title}
              description={post.subtitle}
              onClick={() => window.open(post.url)}
              showEllipses={showEllipses}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CardGrid;
