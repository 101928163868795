import { useTheme } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "20px",

    maxWidth: "1340px",
    width: "100%",
    margin: "auto",
    marginTop: "100px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    "& h1": {
      color: theme.palette.text.primary,
      marginBottom: "20px",
    },
    "& p": {
      marginBottom: "16px",
    },
  },
}));

const TermAndConditionPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <div
        className="richtext-container iris-fbxu07"
        style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
      >
        <h1 data-bn-type="text" id="terms-of-use" className="iris-tcrek1">
          <strong>TERMS OF USE</strong>
        </h1>
        <p className="p2 richtext-paragraph richtext-paragraph-empty iris-127w7vq" />
        <p className="p3 richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Last Updated: 03 June 2024</strong>
          </span>
        </p>
        <p className="p4 richtext-paragraph  iris-1khdsqg">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            These Terms constitute a legally binding agreement between you (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>you</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ” or “
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>your</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”) and Indexx (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”, “
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>we</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”, “
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>our</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ” or “
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>us</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”). The Terms govern your use of the Indexx Services made available
            to you on or through the Platform or otherwise. Indexx Services may
            be provided by Indexx or, if specified in these Terms, any Product
            Terms or any additional terms, by any Indexx Affiliate.
          </span>
        </p>
        <p className="p4 richtext-paragraph  iris-1khdsqg">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            By registering for a Indexx Account, accessing the Platform and/or
            using the Indexx Services, you agree that you have read, understood
            and accepted these Terms, together with any additional documents or
            terms referred to in these Terms. You acknowledge and agree that you
            will be bound by and will comply with these Terms, as updated and
            amended from time to time.
          </span>
        </p>
        <p className="p5 richtext-paragraph  iris-1agb1vv">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            If you do not understand and accept these Terms in their entirety,
            you should not register for a Indexx Account or access or use the
            Platform or any Indexx Service.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-1agb1vv">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>RISK WARNING</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-1agb1vv">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>
              As with any asset, the value of Digital Assets can fluctuate
              significantly and there is a material risk of economic loss when
              buying, selling, holding or investing in Digital Assets. You
              should therefore consider whether trading or holding Digital
              Assets is suitable for you in light of your financial
              circumstances.
            </strong>
          </span>
        </p>

        <p className=" richtext-paragraph  iris-1agb1vv">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>
              It is important that you fully understand the risks involved
              before making a decision to use the Indexx Services.
            </strong>
          </span>
        </p>
        <p className="p8 richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We are not your broker, intermediary, agent or advisor and we have
            no fiduciary relationship or obligation to you in connection with
            any Transactions or other activities you undertake when using the
            Indexx Services. We do not provide investment or consulting advice
            of any kind and no communication or information that we provide to
            you is intended as, or should be construed as, advice of any
            kind.&nbsp;
          </span>
        </p>
        <p className="p4 richtext-paragraph  iris-1khdsqg">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            It is your responsibility to determine whether any investment,
            investment strategy or related transaction is appropriate for you
            according to your personal investment objectives, financial
            circumstances and risk tolerance and you are responsible for any
            associated loss or liability.{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We do not recommend that any Digital Asset should be bought, earned,
            sold or held by you. Before making the decision to buy, sell or hold
            any Digital Asset, you should conduct your own due diligence and
            consult your financial advisor.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            We are not responsible for the decisions you make to buy, earn, sell
            or hold Digital Assets based on the information provided by us,
            including any losses you incur arising from those decisions.
          </span>
        </p>
        <p className="p4 richtext-paragraph  iris-1khdsqg">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>INFORMATION ABOUT OUR AGREEMENT WITH YOU</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>1.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Introduction</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            1.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>About us</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . The Indexx group is an ecosystem centred around an online exchange
            for Digital Assets trading. The Indexx group provides users with a
            trading platform to buy and sell Digital Assets, an integrated
            custody solution allowing users to store their Digital Assets and
            other Digital Asset-related services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            1.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>These Terms</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . By registering to open a Indexx Account you are entering into a
            legally binding agreement with us. These Terms will govern your use
            of the Indexx Services and tell you who we are, how we will provide
            the Indexx Services to you, how these Terms may be changed or
            terminated, what to do if there is a problem, along with other
            important information.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You must read these Terms, together with the documents referenced in
            the Terms, carefully and let us know if you do not understand
            anything.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Where any Local Terms apply to your use of the Indexx Services, such
            Local Terms shall govern your use of the Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            1.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Additional documents</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            These Terms refer to a number of additional documents which also
            apply to your use of the Indexx Services. This includes:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            , which sets out the terms on which we process any personal data we
            collect about you, or that you provide to us. By using the Indexx
            Services, you understand and agree to such processing and you
            promise that all data provided by you is accurate and up to
            date.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Product Terms, which set out additional terms and conditions that
            will apply to your use of specific Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You acknowledge that you will be bound by, and agree that you will
            comply with, any relevant additional terms and conditions that apply
            to your use of the Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>2.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Eligibility</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            2.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Eligibility criteria</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            To be eligible to register for a Indexx Account and use the Indexx
            Services, you must:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            be an individual, corporation, legal person, entity or other
            organisation with the full power, authority and capacity to (1)
            access and use the Indexx Services; and (2) enter into and comply
            with your obligations under these Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            if you are an individual, be at least 18 years old;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            if you act as an employee or agent of a legal entity, and enter into
            these Terms on their behalf, you must be duly authorised to act on
            behalf of and bind such legal entity for the purposes of entering
            into these Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            not have been previously suspended or removed from using Indexx
            Services;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            not be a Restricted Person;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            not currently have an existing Indexx Account; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            g.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            not be located, incorporated, otherwise established in, or resident
            of, or have business operations in:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a jurisdiction where it would be illegal under Applicable Law for
            you to access or use the Indexx Services, or cause us or any third
            party to contravene any Applicable Law; or
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            2.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Amending our eligibility criteria</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may amend our eligibility criteria at any time in our sole
            discretion. Where possible, we will give you notice in advance of
            the change. However, we may occasionally need to make changes
            without telling you in advance. This may include where:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            we are making the change as a result of legal and/or regulatory
            changes;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the changes being made are in your interest; and/or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            there is any other valid reason which means there is no time to give
            you notice.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Where we are unable to give you advance notice, we will let you know
            of the change as soon as possible after it is made.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>3.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>How we contact each other</strong>
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            3.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>How we will contact you</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . We will contact you using the details you provide to us. This may
            include contacting you by email, SMS or telephone. It is important
            that you ensure that your contact details are correct and up to
            date. If your contact details change, you must let us know
            immediately. If you do not, we will not be responsible if you do not
            receive information, notices or other important information from
            us.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>4.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx Services</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            4.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Specific product terms</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . Once you have opened a Indexx Account, you will be able to use the
            Indexx Services in accordance with these Terms and the Product Terms
            that govern your use of each specific Indexx Service.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            4.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Intra-group services</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You acknowledge and agree that some of the Indexx Services may be
            provided by Indexx Affiliates.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>5.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Chat Service</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            5.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Availability of Chat Service</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may make our interactive online chat service available to you (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Chat Service</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”) at any time in connection with your use of any of the Indexx
            Services. By using the Chat Service, you may interact with a bot,
            chatbot, or other non-human. We will disclose the use of a chatbot,
            or other non-human, to the extent required by Applicable Law. When
            engaging with us through use of the Chat Service, you authorise us
            to monitor and save your chats.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            5.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Important information</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The Chat Service is provided as a convenience, often to facilitate
            your understanding of the Indexx Services. Our Chat Service will
            make reasonable efforts to provide you with accurate and current
            information based on your question or need. Nothing we communicate
            in the Chat Service will be considered a legal agreement,
            representation or warranty as to the Indexx Services, processes,
            decisions, or response times. Any personal data shared with us when
            using the Chat Service will be subject to the applicable
            privacy-related policies and notices described in our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            5.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>User Chats</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . In addition, we may make available to you chats that allow you to
            interact directly with other users of the Platform (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>User Chat</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”).
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            5.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Prohibited actions</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You must not use the Chat Service or any User Chat to send any
            abusive, defamatory, dishonest, or obscene message or any messages
            intended to manipulate a market or to spread false or misleading
            information or messages that are otherwise in contravention of
            Applicable Laws, and doing so may result in termination of the Chat
            Service session and may lead to restrictions on the availability of
            Indexx Services to you.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>6.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Fees and calculations</strong>
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            6.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Amending our fees</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . We may adjust our fees from time to time in accordance with clause
            ‎18.3 of these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            6.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Calculations: Any</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            calculations made by Indexx in connection with the Indexx Services
            are final and binding on you in the absence of Manifest Error.
            Calculations will be made in accordance with the stated methodology
            for the relevant Indexx Service in our&nbsp; good faith
            discretion.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>INFORMATION ABOUT YOUR Indexx ACCOUNT</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>7.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Creating a Indexx Account</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            7.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Account opening</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You must create and maintain a Indexx Account in order to access
            the Indexx Services and the Platform. This may be a Indexx Account
            for an individual user, or a Corporate Indexx Account where the user
            is a corporation, entity or other organisation.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            All Indexx Accounts are provided at our absolute discretion. We
            reserve the right to refuse any application for a Indexx Account
            without reason or to limit the number of Indexx Accounts that you
            may hold.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            7.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Sole benefit</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . By opening a Indexx Account you agree that:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            where you are an individual user, you will use your Indexx Account
            only for yourself, and not on behalf of any third party, unless you
            have obtained our prior written consent to do so; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            where you are a corporate user, your Permitted Users will use the
            Corporate Indexx Account only for your benefit, and not on behalf of
            any third party, unless our prior written consent has been obtained.
            Additionally, you will not offer direct market access to the
            Platform to any other party, including through the use of a
            sub-account, unless and until such other party has completed
            identity verification deemed appropriate by Indexx and has
            separately onboarded onto the Platform. Any other party so verified
            shall be considered a “Permitted User” as that term is described
            herein. For avoidance of doubt, any entity or individual using a
            Indexx Sub-Account must be separately verified to be considered a
            “Permitted User” and to be permitted to use the Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You are fully responsible for all activity that occurs under your
            Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            7.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Identity verification</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You will need to comply with our identity verification procedures
            before you are permitted to open a Indexx Account and access and use
            the Indexx Services, whether independently or through a third-party
            service, by providing us with certain information about yourself
            and, where relevant, all of your Permitted Users. All information
            that you provide must be complete, accurate and truthful. You must
            update this information whenever it changes.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You authorise us to make inquiries, whether directly or through
            third parties, that we consider necessary to verify your identity,
            and that of any Permitted Users, or protect you and/or us against
            fraud, money laundering, terrorist financing or other financial
            crime, and to take any action we deem necessary based on the results
            of such inquiries.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            When we carry out inquiries, you acknowledge and understand that
            your personal data, and that of any Permitted Users, may be
            disclosed to identity verification, compliance data recordation,
            credit reference, fraud prevention, or financial crime agencies and
            that these agencies may respond to our inquiries in full.
          </span>
          <br />
          <br />
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You can review our Privacy Notice to have more information about how
            we process your p
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            ersonal data
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            7.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Enhanced due diligence</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . We may also require you to comply with our enhanced due diligence
            procedures by submitting additional information about yourself, your
            business or your Permitted Users, providing additional records or
            documentation, or having face to face meetings with representatives
            of Indexx.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            7.5.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Records</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We keep your personal data to enable your continued use of Indexx
            Services, for as long as it is required in order to fulfil the
            relevant purposes described in this Privacy Notice, and as may be
            required by law such as for tax and accounting purposes, compliance
            with anti-money laundering laws, or as otherwise communicated to
            you. Please review our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            for more information on how we collect and use your personal data
            relating to the use and performance of our Sites and the Indexx
            Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            7.6.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Sub-Accounts</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            At our discretion, you may create and access a Indexx Sub-Account.
            Each natural person associated with a Indexx Sub-Account is subject
            to the identity verification requirements set out in this clause ‎7.
            Only one natural person or corporate entity may be associated with a
            particular Indexx Sub-Account.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>8.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Information requests</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            8.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>When we may request information</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may require information from you at any time for the purposes of
            complying with any Applicable Law, identity verification
            requirements, or in connection with the detection of money
            laundering, terrorist financing, fraud, or any other financial
            crime, or for any other valid reason. You agree to provide us with
            any such information we request and permit us to keep a record of
            the information for the lifetime of your Indexx Account as long as
            it is required to fulfil their intended purposes, or such other
            period as prescribed by Applicable Law.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            8.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>What happens when you provide information</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Your access to your Indexx Account and the Transaction limits that
            apply to your use of the Indexx Services may be altered as a result
            of information collected about you on an ongoing basis. If there is
            a reasonable suspicion that any information provided by you is
            wrong, untruthful, outdated, or incomplete, we may send you a notice
            to request corrections, remove relevant information, or do such
            other things that we consider necessary to ensure that the
            information provided by you is true and correct.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            8.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>If you fail to provide any requested information</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You must comply with any information request we send to you. If
            you decline to provide the requested information, or otherwise do
            not comply in a timely manner, we reserve the right to suspend or
            terminate your access to your Indexx Account, or to all or part of
            the Indexx Services immediately, without notice.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>9.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Accessing your Indexx Account</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            9.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Access</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            To access your Indexx Account you or, where relevant, your Permitted
            Users, must have the necessary equipment (such as a computer or
            smartphone) and access to the internet. Your Indexx Account can be
            accessed directly using the Platform or by such other mode of access
            (including APIs) as we may prescribe. You are only permitted to
            access your Indexx Account by using the Access IDs we provide to you
            or your Permitted Users for such purposes. We may require
            multi-factor authentication to keep your Indexx Account safe and
            secure.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The use of the Platform and other access methods may be subject to
            such additional terms as we communicate to you.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            9.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Restricting access to third parties</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You must ensure that any Indexx Account(s) registered under your
            name will not be used by any person other than yourself or, with
            respect to Corporate Indexx Accounts, your Permitted Users, other
            than in accordance with these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>10.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Account information and Transaction records</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            10.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Your Account History</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You will be able to access your Account History on the Platform. You
            must review your Account History carefully and let us know if you
            see any entries or Transactions that you do not recognise or you
            think are incorrect within fourteen (14) calendar days of the date
            that your Account History is provided or made available to
            you.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            10.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Errors</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may rectify any error in your Account History at any time, and
            reserve the right to void, cancel or reverse any Transaction
            involving or deriving from a Manifest Error or to amend the details
            of such Transaction to reflect what we reasonably consider to be the
            correct or fair details of such a Transaction.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You acknowledge and agree that where you execute any Transaction
            with Improper Intent and/or in the case of Manifest Error, Indexx is
            authorised by you (without any payment or penalty or liability due
            by Indexx and provided that such action is in compliance with
            Applicable Law) to either:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            cancel/void such Transaction and treat such Transaction as if they
            had never been entered into; or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            amend the price(s) of such Transaction (upon notice from Indexx to
            you of the amended price(s)) to reflect the prevailing market
            price(s) as at the relevant time, as determined by Indexx by
            reference to an available source for fair market pricing;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            If Indexx cancels a Transaction pursuant to the rights referred
            herein, Indexx will reverse any transfers of Digital Assets that
            have been made to and/or from your Indexx Account in connection with
            such Transaction as if that Transaction had never taken place.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            If Indexx amends a Transaction pursuant to the rights referred
            herein, Indexx will confirm the details of the amended terms of the
            Transaction to you through an agreed communication channel and will
            affect such transfers of Digital Assets to and/or from your Indexx
            Account as are required to reflect the terms of the amended
            Transaction.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You acknowledge and agree that you shall be solely responsible for
            your own transactions with any third parties that may have been
            entered into in connection with or reliance on any Transaction(s)
            that may be subject to any cancellation/amendment pursuant to
            Indexx’s rights herein.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            10.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Information sharing</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may be required under these Terms or Applicable Law to share
            information about your Indexx Account and Account History with third
            parties and Indexx Affiliates. You acknowledge and agree that we are
            entitled to disclose such information. For more information about
            how we process your personal data you can review our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>USING YOUR ACCOUNT</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>11.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Instructions</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            11.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Giving Instructions</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You must ensure that any Instruction submitted is complete and
            accurate. We are not required to verify the accuracy, authenticity
            or validity of any Instruction and will not monitor or reject
            Instructions on the basis that they are, or appear to be,
            duplicates. However, if we are in doubt as to the accuracy,
            authenticity or validity of an Instruction, we may refuse to act
            upon or defer acting upon any Instruction, or seek further
            information with respect to the Instruction.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Instructions are irrevocable and therefore once an Instruction has
            been submitted you, or your Permitted Users, have no right to
            rescind or withdraw it without our written consent. Your Instruction
            is not deemed to be received by us until it has been received by our
            server. Our record of all Instructions will be conclusive and
            binding on you for all purposes.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            11.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Acting on your Instructions</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            By submitting an Instruction you or your Permitted Users are
            authorising us to initiate the Transaction on your Indexx Account.
            We are therefore authorised to credit or debit (or provide
            settlement information to third parties for the purposes of the
            third party crediting or debiting) your Digital Assets from your
            Indexx Account in accordance with your Instruction. If you have
            insufficient Digital Assets or Fiat Currency in your Indexx Account
            to effect the Transaction (i.e. less than the required amount to
            settle the Transaction and to pay all the fees associated with the
            Transaction), then we have the right to refuse to effect any
            Transaction. Indexx may also refuse to act on instructions to the
            extent permitted by these Terms. It is your responsibility to hold
            sufficient Digital Assets or Fiat Currency credited in your Indexx
            Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            11.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Protection of Instructions</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You are aware that Instructions and information transmitted on the
            Platform or by email are generally transmitted via the internet and
            may be routed via public, transnational installations which are not
            specifically protected. We cannot guarantee that the Instructions
            and information transmitted will be completely protected against
            unauthorised access, and you accept the associated risks.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            11.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Withdrawals</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . Subject to these Terms and any applicable Product Terms, and
            provided that you have sufficient balance on your Indexx Account and
            the relevant Digital Assets are not on hold in your Indexx Account
            in connection with any Indexx Service, you may give Instructions to
            Indexx to transfer Digital Assets to an external wallet address by
            submitting a withdrawal request on the Platform. Upon receipt of the
            withdrawal request, Indexx will: (i) deduct your Indexx Account
            balance; and (ii) initiate an on-chain transfer to an external
            wallet designated by you. Indexx may not process a withdrawal
            request if, in our reasonable opinion, we consider that Applicable
            Law prevents the execution of the relevant withdrawal. Indexx may
            also suspend withdrawals at such time deemed appropriate by Indexx
            to resolve any incidents on the Platform. Once such incidents have
            been resolved, Indexx will resume withdrawals.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>12.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Transactions</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            12.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Entering into Transactions</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You may enter into Transactions directly with us, or directly with
            other users, which may or may not be facilitated by us.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We do not represent or warrant that any Transaction will be
            completed successfully or within a specific time period.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            12.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Unauthorised Transactions</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You are responsible for the control and use of your Indexx
            Account. As such, we will assume that you, or a Permitted User, have
            authorised any Instruction sent from your Indexx Account unless we
            are notified otherwise. It is important that you monitor your
            Account History to ensure any unauthorised or suspicious activity on
            your Indexx Account is identified and notified to us as soon as
            possible. We are not responsible for any claim or losses resulting
            from a Transaction executed as a result of an unauthorised
            Instruction unless you have notified us in accordance with this
            clause and it is confirmed by our internal investigation that you,
            or a Permitted User, have not authorised the Instruction in any way,
            even by mistake, negligence, error or as a consequence of a fraud
            and it is proven that the unauthorized Instruction is solely due to
            a technical issue attributable to Indexx.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            12.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Retention of Transaction information</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . To facilitate compliance with global industry standards for data
            retention, you agree to permit us (but agree to not require us) to
            keep a record of all Transaction information for the lifetime of
            your Indexx Account as long as it is required to fulfil their
            intended purposes, or such other period as prescribed by Applicable
            Law. Please review our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice{" "}
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            for more information on how we collect and use data relating to the
            use and performance of our Sites and the Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>13.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Material interests and conflicts</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            13.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx group</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You understand that Indexx is a member of a group of companies which
            is involved in activities connected with Digital Assets.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            13.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Nature of our duties</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You understand and agree that neither your relationship with us nor
            any Indexx Service we provide to you, nor any other matter, will
            give rise to any duties on our part or on the part of any Indexx
            Affiliate, whether legal, equitable, fiduciary in nature, save as
            are expressly set out in these terms. In particular, we and any
            Indexx Affiliate may from time to time act in more than one
            capacity, and in those capacities we may receive fees or commissions
            from more than one user (including you). You agree that we may act
            in such capacities and provide any other Indexx Services or carry
            out any business with or for you, any Indexx Affiliate or any other
            user.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            13.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Material interests</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You understand and agree that neither we nor any Indexx Affiliate
            will be required to: (1) have regard to any information known to us,
            or to any Indexx Affiliate, which is a material interest; (2)
            disclose any such information to you; or (3) use any such
            information for your benefit. You further acknowledge that from time
            to time we may receive general market information in the course of
            providing Indexx Services to you, which we may use in the ordinary
            course of our business.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            13.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Conflicts of interest</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We have established and maintain effective organisational and
            administrative arrangements with a view to taking all appropriate
            steps to identify and manage conflicts of interest between us and
            our users and relevant third parties, so as to prevent conflicts of
            interest from adversely affecting the interests of our users. In
            cases where such organisational and administrative arrangements are
            not sufficient to ensure that the risks of damage to your interests
            will be prevented, we will inform you of the nature and/or sources
            of the relevant conflicts of interest and the steps taken to
            mitigate those risks in order to allow you to make an informed
            decision as to whether to continue to transact with us. We reserve
            the right at all times to decline to act for you where we are not
            able to manage a conflict of interest in any other way.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>14.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Transaction limits&nbsp;</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            14.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Your Transaction limits</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Your Indexx Account may be subject to a limit on:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the amount or volume of Transactions you may undertake in connection
            with your Indexx Account; and/or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the amount or value of Fiat Currency or Digital Assets you may
            transfer into or out of your Indexx Account,&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in each case in a given period (e.g. daily). Any applicable limits
            are shown in your Indexx Account.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            14.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Changes to your Transaction limits</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We reserve the right to change any Transaction limit that applies to
            your Indexx Account at any time at our absolute discretion. It may
            also be possible for you to request a change in your limits. Any
            change will be made in our absolute discretion and will be subject
            to any further conditions that we deem necessary.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>15.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Supported Digital Assets</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            15.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Supported Digital Assets</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The Indexx Services are only available in connection with Supported
            Digital Assets, which may change from time to time. A list of the
            Supported Digital Assets is published on our Website. We may remove
            or suspend one or more Digital Assets from the list of Supported
            Digital Assets and we will use reasonable commercial efforts to
            notify you in advance, meaning that you will no longer be able to
            access such Digital Assets as part of the Indexx Services and will
            only be permitted to withdraw the Digital Assets from your Indexx
            Account. If Digital Assets that are no longer Supported Digital
            Assets remain in your Indexx Account beyond a specified period
            notified to you, Indexx may in its reasonable discretion convert
            such Digital Assets into a different type of Digital Asset that is a
            stablecoin. Indexx shall notify you in advance of any conversion and
            you may withdraw such Digital Assets within a reasonable period as
            specified by Indexx to you, prior to their conversion into
            stablecoins.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We assume no liability in connection with any attempt to use your
            Indexx Account for Digital Assets that we do not support nor for the
            conversion to a different type of Digital Asset, as described in
            this clause. In addition we assume no liability or obligation
            whatsoever with regard to unsupported Digital Assets sent to a
            Indexx Account or with regard to Supported Digital Assets sent to an
            incompatible Digital Asset wallet address. If you send unsupported
            Digital Assets to a Indexx Account or Supported Digital Assets to an
            incompatible Digital Asset wallet address, then you will lose those
            Digital Assets. For some lost Digital Assets, Indexx may in its sole
            discretion offer you the option to attempt a recovery. We may charge
            fees to process the recovery attempt on your behalf. We will
            calculate all fees at our discretion, and notify you of the
            applicable fees at or before the time you authorize the recovery
            attempt. Indexx does not guarantee in any way the amount of assets
            (if any) that may be recovered in a recovery attempt. The actual
            amount recovered may differ from the estimated recovery amount.
            Indexx does not evaluate or provide any assurance as to the
            authenticity, safety, or security of unsupported Digital Assets. You
            acknowledge and agree that Indexx is not liable for any loss
            incurred during the recovery attempt or subsequent use of the
            recovered Digital Asset.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            15.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Forks</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may temporarily suspend any Indexx Services in relation to a
            particular Digital Asset while we determine whether or not to
            support a Fork. We are under no obligation to support a Fork of a
            Digital Asset that you hold in your Indexx Account, regardless of
            whether any resulting version of such Forked Digital Asset is a
            Dominant Digital Asset or not. If we elect to support a Fork of a
            Digital Asset, we will make a public announcement through our
            Website or by such other means as we may deem appropriate.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You acknowledge that we have no control over, nor do we have the
            ability to influence, the creation or implementation of a Fork. We
            can provide no assurances about the security, functionality or
            supply of any Digital Asset, including both the new Dominant Digital
            Asset or other Digital Assets subject to the relevant Fork. You may
            not be able to trade the Forked Digital Assets on the Platform and
            you may lose any value associated with the relevant Digital Assets.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            15.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Forks and Airdrops.&nbsp; Indexx makes no promises, guarantees or
            warranties on the outcome of or support for potential or proposed
            Forks, Forked Digital Assets or Airdrops. Indexx may determine in
            its sole discretion whether to claim, list or distribute any
            Airdrop, Forked Digital Asset or any other Digital Asset, as well as
            the terms and conditions (including eligibility criteria) that will
            apply to any claim, listing or distribution of any Airdrop or Forked
            Digital Asset. If you wish to participate in a Fork or Airdrop,
            please withdraw the affected Digital Asset to your own private
            wallet well ahead of the potential or proposed Fork or Airdrop.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            15.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Backed Digital Assets</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may from time to time support Digital Assets that purport to be
            backed by or otherwise tied or pegged in value to another asset,
            including without limitation Digital Assets, Fiat Currency or
            commodities such as silver or gold (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Backed Digital Assets</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”). You acknowledge and agree that (a) you have read, understood and
            accepted all of the terms and conditions and risks associated with
            each particular Backed Digital Asset before entering into any
            Transaction relating to that Backed Digital Asset and (b) Indexx
            does not and will not in any circumstances have any no obligation
            whatsoever to purchase, repurchase or effect or facilitate the
            redemption of your Backed Digital Assets. We reserve the right to
            change, suspend, or discontinue any service in relation to any
            Backed Digital Asset at any time in our sole discretion. We make no
            representation as to whether any particular Backed Digital Asset
            will hold its value as against any asset, nor as to the amount or
            quality of reserves or collateral held by each issuer or any third
            party in relation to any Backed Digital Asset.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>16.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Account security</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            16.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Your responsibility</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You are responsible for taking appropriate action to protect your
            hardware and data from viruses and malicious software, and any
            inappropriate material. Except as provided by Applicable Law, you
            are responsible for backing up and maintaining duplicate copies of
            any information you store or transfer through the Indexx Services.
            We are not responsible for any claim or losses resulting from your
            failure to comply with this clause.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            16.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Security measures</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            At all times, you and any Permitted Users shall maintain adequate
            security and control of all of your Access IDs. You are responsible
            for taking the necessary security measures (or ensuring that your
            Permitted Users take such measures) to protect your Indexx Account
            and to keep your Access ID secure, including by:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            strictly abiding by all of our mechanisms or procedures;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            creating a strong password and maintaining security and control of
            your Access IDs;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            keeping the Email Account and telephone number provided to us up to
            date in order to receive any notices or alerts that we may send you;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            never allowing remote access or sharing your computer and/or
            computer screen with someone else when you are logged on to your
            Indexx Account;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            remembering that under no circumstances will we ask you to share
            your passwords or 2-factor authentication codes; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            logging out from the Sites or the Platform at the end of each visit.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You must keep the Email Account and Access IDs secure against any
            attacks and unauthorised access. You must notify us immediately if
            you have knowledge or have reason for suspecting that the security
            of your Email Account, or that of a Permitted User, has been
            compromised or if there has been any unauthorised use of your or any
            Permitted User’s Email Account.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            16.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Monitoring your Account History</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            It is important that you monitor your Account History to ensure any
            unauthorised or suspicious activity on your Indexx Account is
            identified and notified to us as soon as possible. You acknowledge
            that any Security Breach may result in unauthorised access to your
            Indexx Account by third parties and the loss or theft of any Digital
            Assets and/or funds held in your Indexx Account and any associated
            accounts, including your linked bank account(s) and credit card(s).
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            16.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>If you suspect a Security Breach</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            If you suspect a Security Breach, you must ensure that:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            we are notified immediately using the details set out in clause ‎3.1
            and continue to be provided with accurate and up to date information
            throughout the duration of the Security Breach;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your Indexx Account is immediately locked via the disable account
            function on the Platform or via any other method as may be
            prescribed by us from time to time; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you take any other steps that we may reasonably require to reduce,
            manage or report any Security Breach.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We reserve the right to request, and you agree to promptly provide,
            any and all information and documents we deem relevant or necessary
            in connection with an actual or suspected Security Breach.&nbsp; You
            further acknowledge and agree that we may provide such information
            to any third party that we deem necessary in order to investiage or
            resolve any Security Breach.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>OTHER IMPORTANT INFORMATION</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>17.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Privacy</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            17.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>The Privacy Notice</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . Our collection and use of personal data in connection with these
            Terms, the Indexx Services, the Platform and any Site is as provided
            in our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            (as updated from time to time). You acknowledge that we may process
            personal data in relation to you, that you have provided to us, or
            we have collected from you in connection with these Terms and in
            accordance with Privacy Notice. Your personal data will be processed
            in accordance with the Privacy Notice, which shall form part of
            these Terms.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You represent and warrant that:&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <br />
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a. you acknowledge that you have read and understood our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b. our business changes regularly and our{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            will change also. Therefore, if from time to time we provide you
            with a replacement version of the{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            , you will promptly read the{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .&nbsp; &nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>18.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Changes to the Terms, etc.</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            18.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>How and when we can make changes</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . We can make changes to these Terms and any terms and conditions
            incorporated by reference (including any Product Terms) at any time
            and your continued use of the Indexx Services constitutes your
            consent to such changes. Changes to these Terms will be published on
            our website and may also be notified to users separately by email,
            through the app or by such other means as Indexx determines in its
            discretion. &nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Generally, Indexx will try to notify users prior to changes to these
            Terms taking effect.&nbsp; However, we may occasionally need to make
            changes that are effective immediately, in which case users will be
            notified as soon as possible after the changes take effect. The
            circumstances in which changes to these Terms may take effect
            immediately may include, for example and without limitation, where:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the change is to address legal and/or regulatory requirements;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the changes are to make these Terms clearer; and/or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            18.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>When changes come into effect</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . Save where changes come into effect immediately, any update to the
            Terms will come into effect after the changes have been notified to
            users. If you do not wish to accept the changes, you are free to
            close your Indexx Account in accordance with clause ‎19.1 of these
            Terms. Your continued access to or use of any Indexx Services will
            be deemed acceptance of the updated Terms.
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>19.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Closing your Indexx Account&nbsp;</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            19.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Your right to close your Indexx Account</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You may terminate your Indexx Account at any time by following the
            account termination procedures as prescribed by us from time to
            time. You will not be charged for terminating your Indexx Account,
            although you will be required to pay any outstanding amounts owed to
            us. You authorise us to cancel or suspend any pending transactions
            at the time of cancellation, and to deduct any outstanding amounts
            that you owe us from your Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            In certain cases, you may not be able to close your Indexx Account,
            including where:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you are trying to evade an investigation by relevant authorities;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you have a pending Transaction or an open Claim;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your Indexx Account has any outstanding amounts owed to us; or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your Indexx Account is subject to a freeze, hold, limitation or
            reserve.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            19.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>What happens when your Indexx Account is closed</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            If your Indexx Account is closed, you will be required to withdraw
            all Digital Assets held in your Indexx Account. In the event that
            you fail to withdraw your Digital Assets, or if you have not
            accessed your Indexx Account for a continuous period of 90 days,
            will send you notice of our intention to treat your account as
            dormant.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            19.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>What happens when your account becomes dormant.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            If you do not respond to the notice in clause 19.2 within 30 days,
            we may:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            mark your Indexx Account as a dormant account;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            convert the Digital Assets to a different type of Digital Asset. In
            doing so, we will not be liable for any loss of profit, tax
            obligations or any other loss, damage or expense incurred by you
            resulting from such conversion;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            transfer such dormant account and/or any Digital Assets held in that
            account to any third party (including, without limitation, another
            Indexx Affiliate, any third-party custodian or an isolated wallet)
            if we consider it is reasonably necessary to do so. If this happens,
            you have the right to retrieve your Digital Assets, subject to
            satisfying our (or the relevant third party’s) reasonable
            verification requirements and any other applicable terms and
            conditions;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            adjust your dormant account such that you will receive a contractual
            claim on the quantity and type of Digital Assets, which were held on
            your Indexx Account before being converted into a dormant account;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            charge a dormant account fee which may be to cover the cost of
            maintaining the Digital Assets with any Indexx Affiliate or any
            third party, with such fee to be withdrawn directly from the dormant
            account on a monthly basis; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            close a dormant account at any time.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You acknowledge that we will be under no obligation to pay any
            reward, incentive or interest which we might otherwise have agreed
            to pay, under the applicable Product Terms, to your dormant account
            in relation to the Digital Assets credited to it.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>20.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Termination, suspension, holds and restrictions</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            20.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Our right</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may at any time modify or discontinue, temporarily or
            permanently, any portion or feature of the Indexx Services. In
            particular, we may: (1) refuse to complete or block, cancel, or,
            where permitted by Applicable Law, reverse any Transaction you have
            authorised; (2) terminate, suspend, or restrict your access to any
            or all of the Indexx Services; (3) terminate, suspend, close, hold
            or restrict your access to any or all of your Indexx Account(s); (4)
            refuse to transmit information or Instructions to third parties
            (including but not limited to third-party wallet operators); and/or
            (5) take whatever action we consider necessary, in each case with
            immediate effect and for any reason including, but not limited to
            where:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you are not, or are no longer, eligible to use one or more Indexx
            Services;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            we reasonably suspect that:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the person logged into your Indexx Account is not you, or we suspect
            that the Indexx Account has been or will be used for any illegal,
            fraudulent, or unauthorised purposes;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the person logged into your Corporate Indexx Account is not a
            Permitted User, or we suspect that the Corporate Indexx Account has
            been or will be used for any illegal, fraudulent, or unauthorised
            purposes;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            more than one natural person has access to and/or transacts using
            the same Indexx Account, or we suspect that Indexx Account has been
            or will be used for any illegal, fraudulent, or unauthorised
            purposes;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iv.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            information provided by you is wrong, untruthful, outdated, or
            incomplete;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            we have reasonable concerns in relation to your creditworthiness or
            financial status, including:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in the event that you are an individual, you become bankrupt, of
            unsound mind, commit an act of bankruptcy, or have action to place
            you in bankruptcy commenced against you;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in the event that you are acting on behalf of a partnership, any of
            the partners die or become bankrupt or of unsound mind, commit an
            act of bankruptcy, or have action to place any of the partners in
            bankruptcy commenced, or if action is commenced to dissolve and/or
            alter the partners or the constitutions of the partnership;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in the event that you are acting on behalf of a corporation, the
            corporation is unable to pay its debts as and when they are due, or
            action is commenced to place the corporation in insolvency, judicial
            management, receivership, administrative management, or any similar
            or analogous proceedings;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iv.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you convene a meeting of your creditors or propose or make any
            compromise or arrangement with or any assignment for the benefit of
            your creditors;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            pending submission of such information and documents in accordance
            with clause ‎8;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            pending submission of enhanced due diligence in accordance with
            clause ‎7.4;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            we reasonably consider that we are required to do so by Applicable
            Law, or any court or authority to which we are subject in any
            jurisdiction;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            g.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            we have determined or suspect:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            that you have breached these Terms or any Product Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            that you have breached any express or implied warranties in these
            Terms, or any representations you have made;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            that any Transaction is unauthorised, erroneous, fraudulent, or
            unlawful or we have determined or suspect that your Indexx Account
            or the Indexx Services are being used in a fraudulent, unauthorised,
            or unlawful manner;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iv.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            there is any occurrence of money laundering, terrorist financing,
            fraud or any other crime in connection with your Indexx Account or
            your use of the Indexx Services;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            h.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the use of your Indexx Account is subject to any pending, ongoing or
            threatened litigation, investigation, or judicial, governmental or
            regulatory proceedings and/or we perceive a heightened risk of legal
            or regulatory non-compliance associated with your Indexx Account
            activity;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you owe amounts to Indexx that are not satisfied, whether due to a
            chargeback or on any other basis;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            j.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any email communication to your Email Account is returned as
            undeliverable;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            k.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            an issue has arisen with the verification of your identity;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            l.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you have taken any action that may circumvent our controls such as
            opening multiple Indexx Accounts without our written consent or
            abusing promotions which we may offer from time to time; or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            m.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            there is any other valid reason which means we need to do so.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We will take reasonable steps to provide you with appropriate
            notice. However, there might be times when we are required not to do
            so by Applicable Law.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            20.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Your acknowledgement</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You acknowledge and agree that:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the examples set out in clause ‎20.1 above of when we might take
            action to terminate, suspend, close or restrict your access to your
            Indexx Account and/or the Indexx Services is a non-exhaustive list;
            and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            our decision to take certain actions, including, without
            limitations, to terminate, suspend, or restrict your access to your
            Indexx Account or the Indexx Services, may be based on confidential
            criteria that are essential to our risk management and security
            protocols. You agree that we are under no obligation to disclose the
            details of our risk management and security procedures to you.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>20.3.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>What happens when we exercise our right</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . Where we terminate, suspend, hold or restrict your access to one
            or more Indexx Services:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            if you have Instructions, trades, positions or Transactions that are
            open, they may be closed by you, or by us, depending on the
            circumstances of the termination, suspension, hold, restriction or
            other action we take;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any chargeback resulting from the use of your Indexx Account or
            Indexx Services may result in an immediate suspension and/or
            restriction of your Indexx Account and Indexx Services;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            to reactivate suspended and/or restricted Indexx Account or Indexx
            Services, you may be required to reimburse us for the full value of
            the chargeback, including any amounts that are owing to us including
            any applicable fees; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you are liable for any credited amounts in case of a chargeback, and
            you authorise and grant us the right to deduct costs and fees
            directly from any assets in your Indexx Account without notice.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>20.4.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Unlawful possession</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . If we are informed and reasonably believe that any Digital Assets
            or Fiat Currencies held in your Indexx Account are stolen or
            otherwise are not lawfully possessed by you (whether by error or
            otherwise), we may, but have no obligation to, place a hold on the
            affected funds and your Indexx Account. Where we place a hold on
            some or all of any Digital Assets or Fiat Currencies held in your
            Indexx Account, or on your entire Indexx Account, we may continue
            such hold until such time as evidence, acceptable to us, proves that
            you are entitled to possession of the Digital Assets and/or Fiat
            Currency held in your Indexx Account. We will not get involved in
            any dispute, or the resolution of the dispute, relating to any
            Digital Assets and/or Fiat Currency held in your Indexx
            Account.&nbsp;
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>INTELLECTUAL PROPERTY</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>21.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Background IP</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The Indexx IP shall remain vested in Indexx.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>22.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Licence of Indexx IP</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We grant to you a non-exclusive licence for the duration of these
            Terms, or until we suspend or terminate your access to the Indexx
            Services, whichever is sooner, to use the Indexx IP, excluding the
            Trade Marks, solely as necessary to allow you to receive the Indexx
            Services for non-commercial personal or internal business use, in
            accordance with these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>23.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Licence of User IP</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            23.1.{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Your grant of licence.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            You grant to us a perpetual, irrevocable, royalty-free, worldwide
            and non-exclusive licence to use the User IP to the extent it:&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            forms part of, or is necessary for the use of, any Created IP; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            is necessary to allow us to provide you with the Indexx Services,
            from time to time.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            23.2.{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Our right to sub-licence.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            The licence granted by you under this clause ‎includes our right to
            sub-licence to a third party to the extent required to enable us and
            any Indexx Affiliates to provide you with the Indexx Services, or
            any part of them.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>24.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Created IP</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            24
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            1
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Created IP.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            The Created IP shall automatically vest in us from time to time on
            the date on which{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            it is created.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            24
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            2
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Assignment.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            You hereby assign to us (and agree to procure that any agents,
            representatives{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            or contractors assign), with full title guarantee, title to all
            present and future rights and interest in{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the Created IP.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            If requested to do so, you shall (and agree to procure that any
            agents, representatives or contractors shall), without charge to us,
            sign and/or execute all documents and do all such acts as we may
            require to perfect the assignments under this clause.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>25.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>General</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            25.1
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>We are not responsible</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You agree and acknowledge that: (1) we are not responsible for any{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            User Material (whether provided by you or by third parties) which
            may be made available on the{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Platform or the Sites; and (2) use of any such User Material is at
            your own risk and that we do{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            not provide any warranties in relation to the same.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            25.2
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Our rights</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We shall have the right at our sole and absolute discretion to
            remove, modify or{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            reject any content that you submit to, post or display on the
            Platform or the Sites (including any{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            User Material) for any reason. We reserve the right to take any
            actions as we deem appropriate{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            at our sole discretion, including giving a written warning to you,
            removing any User Material,{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            recovering damages or other monetary compensation from you,
            suspending or terminating your{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Indexx Account (if any), or suspending your access to the Platform
            and/or the Sites. We shall{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            also have the right to restrict or ban you from any and all future
            use of any Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            25.3
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Recording. </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You agree that we may record any communications, electronic, by
            telephone, over video call, or otherwise, that we have with you in
            relation to these Terms, and that any recordings that we keep will
            constitute evidence of the communications between you and us. You
            agree that telephone conversations and video calls may be recorded
            so that we can respond to inquiries, ensure compliance with
            applicable laws, improve our services and provide customer support.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>YOUR OBLIGATIONS AND LIABILITY</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>26.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Prohibited use</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            By opening a Indexx Account or carrying out any Transaction, and
            without prejudice to any other restriction or limitation set out in
            these terms, you agree that you and any Permitted User will not:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            breach these Terms or any agreement entered into pursuant to, or in
            connection with, these Terms, including, but not limited to, any
            Product Terms;
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            use Indexx Services for resale or commercial purposes, including
            transactions on behalf of other persons or entities, unless
            expressly agreed by us in writing;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            use the Indexx Services for anything which, in Indexx’s sole
            opinion, is conduct designed to control or artificially affect the
            price of any Digital Asset (market manipulation) including, without
            limitation,&nbsp; pump and dump schemes, wash trading, self-trading,
            front running, quote stuffing, and spoofing or layering) regardless
            of whether prohibited by Applicable Law;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            engage in fraudulent activities, or cause us to suspect that you or
            any Permitted User have engaged in fraudulent activities and/or
            Transactions;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            use Indexx Services to conduct or participate in lotteries; gambling
            activities; bidding fee auctions; sports forecasting or odds making;
            fantasy sports leagues with cash prizes; internet gaming; contests;
            sweepstakes; or games of chance;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            g.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (1) receive, or attempt to receive, funds from both us and another
            user for the same Transaction during the course of a Claim; (2)
            conduct your business or use the Indexx Services in a manner that
            results in, or may result in, complaints, disputes, claims,
            reversals, chargebacks, fees, fines, penalties, or other liability
            to us, other users, third parties, or yourself; and (3) allow your
            Indexx Account to have a negative value or quantity of Digital
            Assets;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            h.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            provide false, inaccurate or misleading information in connection
            with your use of the Indexx Services, in communications with us, or
            otherwise connected with these Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (1) use any deep linking, web crawlers, bots, spiders or other
            automatic devices, programs, scripts, algorithms or methods, or any
            similar or equivalent manual processes to access, obtain, copy or
            monitor any part of the Platform, or replicate or bypass the
            navigational structure or presentation of Indexx Services in any
            way, in order to obtain or attempt to obtain any materials,
            documents or information in any manner not purposely provided
            through Indexx Services; (2) attempt to access any part or function
            of the Platform without authorisation, or connect to Indexx Services
            or any of our servers or any other systems or networks of any Indexx
            Services provided through the Platform by hacking, password mining
            or any other unlawful or prohibited means; (3) probe, scan or test
            the vulnerabilities of Indexx Services or any network connected to
            the Platform, or violate any security or authentication measures on
            Indexx Services or any network connected to Indexx Services; (4)
            reverse look-up, track or seek to track any information of any other
            users or visitors of Indexx Services; (5) take any actions that
            impose an unreasonable or disproportionately large load on the
            infrastructure of systems or networks of Indexx Services or Indexx,
            or the infrastructure of any systems or networks connected to Indexx
            Services; (6) use any devices, software or routine programs to
            interfere with the normal operation of Indexx Services or any
            transactions on Indexx Services, or any other person’s use of Indexx
            Services; or (7) forge headers, impersonate, or otherwise manipulate
            identification, to disguise your identity or the origin of any
            messages or transmissions you send to us;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            j.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            modify or adapt the whole or any part of the Platform or combine or
            incorporate the Platform into another programme or application;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            k.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            disassemble, decompile, reverse-engineer or otherwise attempt to
            derive the source code, object code underlying concepts, ideas and
            algorithms of the Platform or any components thereof;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            l.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            modify, replicate, duplicate, copy, download, store, further
            transmit, disseminate, transfer, disassemble, broadcast, publish,
            remove or alter any copyright statement or label, or licence,
            sub-licence, sell, mirror, design, rent, lease, private label, grant
            security interests in such Indexx IP or any part of the intellectual
            properties, or create derivative works or otherwise take advantage
            of any part of the Indexx IP;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            m.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            facilitate any viruses, Trojan horses, worms or other computer
            programming routines that may damage, detrimentally interfere with,
            surreptitiously intercept, or expropriate any system, data or
            information in connection with the Indexx Services;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            n.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (1) use an anonymizing proxy; (2) use any temporary, disposable,
            self-destructive, or similar email address when opening a Indexx
            Account and/or using the Indexx Services; (3) use any device,
            software, or routine to bypass our robot exclusion headers, or
            interfere or attempt to interfere with our Sites or the Indexx
            Services; and (4) take any action that may cause us to lose any of
            the services from our internet service providers, or other
            suppliers;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            o.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            create, or purport to create, any security over your Fiat Currency
            or Digital Assets held in any of your Indexx Account without our
            prior written consent;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            p.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            violate, or attempt to violate, (1) any Applicable Law; or (2) ours
            or any third party’s copyright, patent, trademark, trade secret, or
            other intellectual property rights, or rights of publicity or
            privacy; and/or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            q.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            access, use, or attempted to access or use, Indexx Services directly
            or indirectly with (1) jurisdictions Indexx has deemed high risk,
            including but not limited to, Cuba, Iran, North Korea, Syria, Crimea
            region or (2) persons Indexx has deemed high risk, including but not
            limited to, individuals or entities named as a restricted person or
            party on any list maintained by the United States of America.,
            United Kingdom, European Union or United Nations, including the
            sanctions lists maintained by the U.S. Office of Foreign Assets
            Control or the Denied Persons List or Entity List maintained by the
            U.S. Department of Commerce.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>27.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Representations and warranties</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You hereby represent and warrant to us, at all times, the following:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            all documents and information you provide to us are true, accurate,
            complete, and up to date in all respects, and may be relied upon by
            us in determining whether or not you are eligible to access the
            Platform or to use the Indexx Services;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            all decisions made in connection with these Terms were solely and
            exclusively based on your own judgement and after your own
            independent appraisal of your financial resources, ability and
            willingness to take relevant risks and financial objectives;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you have full power, authority, and capacity to (1) access and use
            the Platform and/or the Indexx Services; and (2) enter into and
            deliver, and perform your obligations under these Terms and any
            agreement entered into pursuant to, or in connection with, these
            Terms, including, but not limited to, any Product Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            if you are a corporation, partner in a partnership, or trustee of a
            trust:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the corporation, partnership or trust has and will be operated in a
            way that is compliant with Applicable Law, and any partnership or
            trust deeds (or other like documents);
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you will notify us immediately if there is any change, by way of
            resignation, removal, appointment or death, of any of the directors,
            partners, trustees, settlor(s) or ultimate beneficial owners or any
            person authorised to operate your Indexx Account; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you will inform us immediately if the corporation, partnership, or
            trust is dissolved either on a voluntary or involuntary basis;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            all consents, permissions, authorisations, approvals and agreements
            of third parties and all authorisations, approvals, permissions,
            consents, registrations, declarations, filings with any Regulatory
            Authority, governmental department, commission, agency or other
            organisation having jurisdiction over you which are necessary or
            desirable for you to obtain in order to (1) access and use the
            Platform and/or the Indexx Services; and (2) enter into and deliver,
            and perform the Transactions contemplated under these Terms and any
            agreement entered into pursuant to, or in connection with, these
            Terms, have been unconditionally obtained in writing, disclosed to
            us in writing, and have not been withdrawn or amended;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            these Terms and any agreement entered into pursuant to, or in
            connection with, these Terms constitute valid and legally binding
            obligations, enforceable against you in accordance with their
            respective terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            g.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            that you are not a Restricted Person;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            h.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            if you are a legal entity, you are duly incorporated, duly
            organised, and validly existing under the laws of your jurisdiction
            and have full power to conduct your business. If you are an
            individual, you are not less than 18 years old; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your access and use of the Platform and/or the Indexx Services, your
            execution and delivery of, and the performance of your obligations
            under these Terms and any agreement entered into pursuant to, or in
            connection with, these Terms, will not:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            if you are a legal entity, partner in a partnership or trustee of a
            trust result in a breach of or conflict with any provision of your
            constitution, articles of association, partnership agreement, trust
            deed or equivalent constitutive documents;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            result in a breach of, or constitute a default under, any
            instrument, agreement, document or undertaking to which you are a
            party or by which you or any of your property is bound or subject;
            and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            result in you, or cause us or any third party to, breach any
            Applicable Law, decree or judgement of any court, or any award of
            any arbitrator or those of any governmental or Regulatory Authority
            in any jurisdiction.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>28.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Technology disclaimers</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            28.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>No representation or warranty</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Indexx Services and any information provided on the Sites and the
            Platform, including Chat Services, are provided on an “as is” and
            “as available” basis without any representation or warranty, whether
            express or implied, to the maximum extent permitted by Applicable
            Law. Specifically, we disclaim any implied warranties of title,
            merchantability, fitness for a particular purpose and/or
            non-infringement. We do not make any representations or warranties
            that access to the Sites, the Platform, any of your Indexx
            Account(s), Indexx Services, or any of the materials contained
            therein, will be continuous, uninterrupted, timely or error-free.
            This could result in the inability to trade on the Platform for a
            period of time and may also lead to time delays.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            28.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Suspension of access</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We may, from time to time, suspend access to your Indexx Account
            and/or the Indexx Services, for both scheduled and emergency
            maintenance. We will make reasonable efforts to ensure that
            Transactions on the Platform are processed in a timely manner, but
            we make no representations or warranties regarding the amount of
            time needed to complete processing, which is dependent upon many
            factors outside of our control.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            28.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Content</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Although we make reasonable efforts to update the information on the
            Sites and the Platform, we make no representations, warranties or
            guarantees, whether express or implied, that the content on the
            Sites and the Platform, including information in relation to the
            Indexx Services, is accurate, complete or up to date.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            28.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Third-party websites</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Links to third-party websites (including, without limitation,
            content, materials, and/or information in the third-party websites)
            may be provided as a convenience but they are not controlled by us.
            You acknowledge and agree that we are not responsible for any aspect
            of the content, materials, information or services contained in any
            third-party websites accessible or linked from the Platform or the
            Sites.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            28.5.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Network Access and Compatibility</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            : You are responsible for obtaining the data network access
            necessary to use the Indexx Services. You are responsible for
            acquiring and updating compatible hardware or devices necessary to
            access and use the Indexx Services and Sites and any updates
            thereto. Indexx does not guarantee that the Indexx Services, or any
            portion thereof, will function on any particular hardware or
            devices.&nbsp; The Indexx Services may be subject to malfunctions
            and delays inherent in the use of the internet and electronic
            communications.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>29.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indemnity</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            29.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Third-party claims</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You hereby undertake and agree to indemnify us and hold us
            harmless upon demand from and against any claims, suits, actions,
            demands, disputes, allegations, or investigations brought by any
            third-party, governmental authority, or industry body, and all
            claims, liabilities, damages (actual and consequential), losses
            (including any direct, indirect or consequential losses, loss of
            profit, loss of reputation), costs, and expenses, including without
            limitation all interest, penalties and legal and other reasonable
            attorneys’ fees and other professional costs and expenses (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Losses</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”), arising out of or in any way connected with:&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your access to or use of your Indexx Account and/or the Indexx
            Services;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your breach or alleged breach of these Terms or your contravention
            of any other clause or sub-clause of these Terms, including any
            Product Terms and any other terms and conditions incorporated by
            reference;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your contravention of any Applicable Law; and&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your violation of the rights of any third party.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            29.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Release</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You hereby irrevocably and unconditionally agree to release us from
            any and all claims and demands (and waive any rights you may have
            against us now or in future in relation to any Losses you may suffer
            or incur), arising directly or indirectly out of or in connection
            with any dispute that you have with any other user or other third
            party in connection with the Indexx Services (including any Digital
            Asset Transactions) or the subject matter of these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>30.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Liability</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>30.1</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Our Liability.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            Neither Indexx nor any Indexx Affiliate will have any responsibility
            or liability for any loss suffered by you or any third party, except
            to the extent that such loss arises solely and directly as a result
            of the gross negligence, willful misconduct, actual fraud or
            material and continuing breach of obligation under these Terms by
            Indexx. Without prejudice to the foregoing and notwithstanding any
            other clause in these Terms, in no event will the liability of
            Indexx and all Indexx Affiliates in aggregate exceed the amount of
            fees paid by you to Indexx in the 12 month period immediately before
            the event giving rise to the loss. Such sum shall be paid by us to
            you in full and final settlement and satisfaction of ours and any
            Indexx Affiliate’s entire liability for any and all losses and
            claims, howsoever arising, from the relevant events. You acknowledge
            and agree that neither Indexx nor any Indexx Affiliate is aware of
            any special circumstances pertaining to you, and that damages are an
            adequate remedy and that you shall not be entitled to any other
            claims or remedies at law or in equity, including but not limited
            to, any claim in rem, injunction, and/or specific performance.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>30.2</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Limitations on liability</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Notwithstanding any other clause in these Terms, you hereby
            acknowledge and agree that in no event will we or any Indexx
            Affiliate be responsible or liable to you or any other person or
            entity for:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any direct or indirect losses (including loss of profits, business
            or opportunities), damages, or costs, whether arising out of or in
            connection with the Indexx Services or otherwise, including but not
            limited to:
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the operation of the protocols underlying any Digital Asset, their
            functionality, security, or availability;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            whether Backed Digital Assets hold their value as against any asset,
            or if the issuer of the Backed Digital Asset holds sufficient
            reserves in relation to any Backed Digital Asset;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iv.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any action or inaction in accordance with these Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            v.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any inaccuracy, defect or omission of Digital Assets price data, any
            error or delay in the transmission of such data, and interruption in
            any such data;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            vi.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            regular or unscheduled maintenance we carry out including any
            service interruption and change resulting from such
            maintenance;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            vii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the theft of a device enabled to access and use Indexx Services;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            viii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            other users’ actions, omissions or breaches of these Terms, and any
            damage caused by actions of any other third parties;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ix.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (1) for any damage or interruptions caused by any computer viruses,
            spyware, or other malware that may affect your computer or other
            equipment, or any phishing, spoofing, or other attack; (2) in the
            event that your hardware fails, is damaged or destroyed or any
            records or data stored on your hardware are corrupted or lost for
            any reason; or (3) for your use of the internet to connect to the
            Indexx Services or any technical problems, system failures,
            malfunctions, communication line failures, high internet traffic or
            demand, related issues, security breaches or any similar technical
            problems or defects experienced;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            x.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            our decision to reject your application to open a Indexx Account(s);
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xi.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any termination, suspension, hold or restriction of access to any
            Indexx Account or Indexx Services, including your inability to
            withdraw Digital Assets, issue Instructions or enter into
            Transactions during the period of any suspension, hold or
            restriction, in accordance with these Terms or any Product Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any Transaction limits applied to your Indexx Account;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xiii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any election by us to support or not support Digital Assets;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xiv.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            us being unable to contact you using the contact information you
            provided;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xv.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            us closing a dormant account;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xvi.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the failure of a Transaction or the length of time needed to
            complete any Transaction;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xvii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            our refusal or delay in acting upon any Instruction;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xviii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any breach of security of your Email Account or a Security
            Breach;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xix.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            losses suffered by you as a result of third party action, including
            third party fraud or scams that involve Indexx only as the recipient
            of your Fiat Currency or Digital Assets, and/or the conversion of
            Fiat Currency to Digital Assets;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xx.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            losses suffered by you as a result of the transfer of Digital Assets
            away from our platform at your request;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xxi.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any losses arising or in connection with new offerings of Digital
            Assets, initial coin offerings (ICOs), or the decision to list or
            not list Digital Assets on the Platform;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xxii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a Network Event, a Fork or an Airdrop;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xxiii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the correctness, quality, accuracy, security, completeness,
            reliability, performance, timeliness, pricing or continued
            availability of the Indexx Services or for delays or omissions of
            the Indexx Services, or for the failure of any connection or
            communication service to provide or maintain your access to the
            Indexx Services, or for any interruption in or disruption of your
            access or any erroneous communications between us, regardless of
            cause; and&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            xxiv.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any Transactions, Instructions, or operations effected by you or
            purported to be effected by you through your Email Account or Indexx
            Account;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any loss of business, profits, anticipated savings or opportunities,
            or any special, punitive, aggravated, incidental, indirect or
            consequential losses or damages, whether arising out of or in
            connection with our Sites, the Platform, your Indexx Account, the
            Indexx Services, these Terms, the Product Terms, the{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            and/or any agreement entered into pursuant to, or in connection
            with, these Terms or otherwise;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any losses or damages you may incur due to Manifest Error and/or in
            the event of extreme market volatility and/or as a result of any
            cancellation/amendment of any Transactions, howsoever arising,
            whether direct or indirect, special or consequential, including, but
            not limited to, loss of profit and loss of opportunity even if
            Indexx was aware&nbsp; of the possibility of such loss or damage
            arising or if such loss or damage was reasonably foreseeable; and/or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any losses forming part of a Claim that has not been commenced by
            way of formal legal action WITHIN ONE CALENDAR YEAR of the
            commencement of matters giving rise to the Claim. YOU AGREE AND
            ACKNOWLEDGE THAT THIS CLAUSE VARIES ANY LIMITATION PERIOD OTHERWISE
            APPLICABLE BY LAW, AND THAT IF APPLICABLE LAW PROHIBITS THIS, THIS
            CLAUSE WILL BE READ AS A LIMITATION PERIOD OF THE MINIMUM
            ENFORCEABLE LENGTH. WITHOUT PREJUDICE TO THE GENERALITY OF THE
            FOREGOING, YOUR ATTENTION IS ALSO DRAWN TO CLAUSE 32 AGREEING THAT
            CLAIMS SHALL BE RESOLVED EXCLUSIVELY BY WAY OF BINDING ARBITRATION.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>30.3</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Damage or interruption</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We are not liable for any damage or interruptions caused by any
            computer viruses, spyware, scareware, Trojan horses, worms, or other
            malware that may affect your computer or other equipment, or any
            phishing, spoofing, or other attack. We advise the regular use of a
            reputable and readily available virus screening and prevention
            software. You should also be aware that SMS and email services are
            vulnerable to spoofing and phishing attacks and should use care in
            reviewing messages purporting to originate from us. You and, where
            relevant, your Permitted Users are responsible for all login
            credentials, including usernames and passwords and must keep
            security details safe at all times.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>30.4</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Fiat Currency. </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            For the avoidance of doubt, any balance on your Indexx Account
            displayed and denominated in a Fiat Currency shall not be taken to
            mean that Indexx directly holds Fiat Currency, nor does it mean that
            Indexx receives, holds, or releases any Fiat Currency or engages in
            deposit taking, remittance or similar activity in respect of Fiat
            Currency.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>
              RESOLVING DISPUTES: FORUM, ARBITRATION, CLASS ACTION WAIVER
            </strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>
              For the avoidance of doubt, nothing in this section will deprive
              you of any mandatory legal right from which you benefit under
              Applicable Law.
            </strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>31.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Notice of Claim and Dispute Resolution Period</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            31.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Please contact Indexx first if you have any concerns with the
            Services. Indexx wants to address your concerns without resorting to
            formal legal proceedings, if possible. A ticket number will be
            assigned to you if your concerns cannot be addressed to your
            satisfaction at first instance. The issuance of the ticket number
            commences the internal dispute resolution procedure. Indexx will
            attempt to resolve the dispute internally as soon as possible. The
            parties agree to negotiate in good faith to resolve the dispute
            (which discussions shall remain confidential and be subject to
            applicable rules protecting settlement discussions from use as
            evidence in any legal proceeding).
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            31.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            For the avoidance of doubt, the submission of a dispute to Indexx
            for resolution internally and the delivery of a Notice of Claim to
            Indexx are prerequisites to commencement of an arbitration
            proceeding (or any other legal proceeding).
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            31.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            During the arbitration, the amount of any settlement offer made by
            you or Indexx shall not be disclosed to the arbitrator.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>32.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Agreement to Arbitrate</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Aside from where Applicable Law requires or provides you with a
            choice otherwise, you and Indexx agree that, subject to the
            immediately preceding clause above (Notice of Claim and Dispute
            Resolution Period), any Claim shall be determined by mandatory final
            and binding individual (not class) arbitration administered by the
            Hong Kong International Arbitration Centre (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>HKIAC</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”) in accordance with the HKIAC Rules for the time being in force,
            which rules are deemed incorporated by reference in this clause.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The seat of arbitration shall be Hong Kong.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The Tribunal shall consist of one (1) arbitrator to be appointed in
            accordance with the HKIAC Rules for the time being in force.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The language of the arbitration shall be in English.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.5.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You and Indexx further agree that the arbitrator shall have the
            exclusive power to rule on his or her own jurisdiction, including
            without limitation any objections with respect to the existence,
            scope or validity of the Agreement to Arbitrate, or to the
            arbitrability of any Claim.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.6.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The arbitration provisions set forth in this clause will survive
            termination of these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.7.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Limitation period for filing: Any arbitration against Indexx must be
            commenced by filing and serving a Notice of Arbitration in
            accordance with the HKIAC Rules within one (1) year after the date
            that the user asserting the Claim first found out or reasonably
            should have found out the alleged act, omission or default giving
            rise to the Claim (“
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Limitation Period</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ”).&nbsp; For the avoidance of doubt, the Limitation Period shall
            include the Dispute Resolution Period set out at clause 31.1.&nbsp;
            There shall be no right to any remedy or relief for any Claim by the
            user if the Notice of Arbitration in respect of that Claim is not
            filed and served on Indexx within that Limitation Period.&nbsp; If
            the Limitation Period is contrary to applicable law, the user shall
            be required to bring any Claim against Indexx within the shortest
            time period permitted by the applicable law. A Notice of Arbitration
            may be served on Indexx in accordance with the Applicable Laws and
            rules of service.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            32.8.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Notice: if Indexx commences arbitration against you, Indexx will
            give you notice at the email address or mailing address you have
            provided. You agree that any notice sent to this email or mailing
            address shall be deemed effective for all purposes, including
            without limitation to determinations of the adequacy of service. It
            is your obligation to ensure that the email address and/or mailing
            address on file with Indexx is up-to-date and accurate.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>33.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Confidentiality</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>33.1.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            The parties agree that the arbitration shall be kept confidential.
            The existence of the arbitration, the existence or content of the
            Claim, all documents and information provided or exchanged in
            connection with the arbitration, and any submissions, orders or
            awards made in the arbitration shall be kept confidential and no
            party shall disclose any of the foregoing to any third party except
            the tribunal, the HKIAC, the parties, their counsels, experts,
            witnesses, accountants and auditors, insurers and reinsurers, and
            any other persons necessary to the conduct of the arbitration.
            Notwithstanding the foregoing, a party may disclose such
            confidential information:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            if the written consent of the other party is obtained;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            to the extent required by applicable law or by the regulations of
            any regulatory or supervisory authority of competent jurisdiction to
            which the party is or may become subject to or pursuant to any order
            of court or other competent authority or tribunal of competent
            jurisdiction;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in connection with the commencement, pursuit or defence by a party
            of any bona fide legal proceedings to enforce or challenge any award
            rendered in the arbitration; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            to the extent that the relevant confidential information is in the
            public domain otherwise than by breach of this agreement.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            This term of confidentiality in this clause shall survive
            termination of these Terms and conclusion or stay of any arbitration
            brought pursuant to these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>34.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Governing law</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Aside from where Applicable Law requires or provides you with a
            choice otherwise, these Terms (including this arbitration agreement)
            shall be governed by, and construed in accordance with, the laws of
            Hong Kong.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>35.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Class action waiver</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You and Indexx agree that any Claims shall be brought against Indexx
            in an arbitration on an individual basis only and not as a plaintiff
            or class member in a purported class or representative action. You
            further agree to waive any right for such Claims to be brought,
            heard, or arbitrated as a class, collective, representative, or
            private attorney general action, to the extent permissible by
            applicable law. Combining or consolidating individual arbitrations
            into a single arbitration is not permitted without the consent of
            Indexx.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>GENERAL&nbsp;</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>36.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>General terms</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.1.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Applicable Law</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . You and any Permitted User must comply with all Applicable Law,
            licensing requirements and third party rights (including data
            privacy laws and anti-money laundering and countering the financing
            of terrorism laws) in your use of the Indexx Services, your Indexx
            Account and the Platform.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.2.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Notices</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . We may give notice by email to your Email Account. It is your
            responsibility to ensure that the Email Account is up to date and
            accurate. Notices may be given, and are deemed to be received, if
            sent to your Email Account, whether or not a notice of delivery
            failure is received.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You may give us notices only as we direct, which may change from
            time to time.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Any notices, consent or other communication given under these Terms
            must be in writing, in English, and signed or otherwise authorised
            by the party giving it.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.3.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Announcements</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            All official announcements, news, promotions, competitions and
            Airdrops will be listed on the Website. These announcements are
            important, and may relate to issues that may impact the value of
            your Digital Assets, or their security. You are responsible for
            monitoring the Website and reading and considering these
            announcements.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.4.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Entire agreement</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . The Terms, together with any applicable Product Terms, constitute
            the whole agreement between you and us with respect to the Indexx
            Services. Each party acknowledges that it has not relied on, and
            shall have no right or remedy in respect of, any statement,
            representation, assurance or warranty (whether made negligently or
            innocently) other than as expressly set out in the Terms or any
            Product Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.5.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Assignment</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You may not assign or transfer any of your rights or obligations
            under the Terms without our prior written consent, which may, in
            some cases, require additional information to be provided or
            enhanced due diligence to be performed. However, we may assign or
            transfer any of our rights or obligations under the Terms at any
            time to anyone else, including, without limitation, in connection
            with any merger, acquisition, or other corporate reorganisation
            involving Indexx.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.6.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Invalidity</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . If, at any time, any clause or sub-clause of the Terms is or
            becomes illegal, invalid, or unenforceable in any respect, neither
            the legality, validity or enforceability of the remaining clauses or
            sub-clauses will in any way be affected or impaired.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.7.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Records</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You agree that we may record any telephone, email and chat
            conversations with you, as well as any other forms of
            communications, including communication used to give Instructions or
            effect Transactions, between you and us, and that the recordings may
            be used as evidence in any proceedings relating to any agreement
            with you. These records will be our sole property.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.8.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Language</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            These Terms may be translated into a language other than the English
            language. Any such translation is provided solely for your
            convenience. In the event of inconsistency or ambiguity, the English
            text will prevail.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.9.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Third party rights</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . Other than in relation to Indexx Affiliates, nothing expressed or
            referred to in these Terms will be construed to give any person
            other than the parties to these Terms any legal or equitable right,
            remedy, or claim under or with respect to these Terms or any clause
            or sub-clause of these Terms. The Terms and all of its clauses and
            sub-clauses are for the sole and exclusive benefit of the parties to
            these Terms and their successors and permitted assigns.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.10.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Survival</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . All clauses and sub-clauses of these Terms, which by their nature
            extend beyond the expiration or termination of these Terms, will
            continue to be binding and operate after the termination or
            expiration of these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.11.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Relationship of the parties</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . Indexx is not an agent of you in the performance of these Terms.
            These Terms shall not be interpreted as facts or evidence of an
            association, joint venture, partnership, or franchise between the
            parties.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.12.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Digital Assets. </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We record in your Indexx Account the quantity and type of any
            Digital Assets that are held to your credit. Digital Assets held by
            Indexx to your credit will not be segregated on-chain in different
            wallets from Digital Assets held to the credit of other users or
            Digital Assets maintained by Indexx for operating and business
            purposes. Indexx uses an internal ledger to record customer
            entitlements to the quantity and type of Digital Assets. Each user
            will have an account which is a ledger-based (off-chain) account (or
            sub-account) within the Indexx system with a unique identifier (UID)
            and associated login credentials, against which the relevant user
            credit balances are recorded. Indexx is not a trustee of, and does
            not owe any trustee duties in relation to, any Digital Assets held
            to your credit. When you Instruct Indexx to transfer or otherwise
            deal with Digital Assets, Indexx will not use any specifically
            identifiable Digital Assets to act on your Instructions.&nbsp;
            Subject to these Terms, Indexx will use Digital Assets in the same
            quantity and of the same type as those credited to your Indexx
            Account when acting on your Instructions. Similarly, you will not
            have the right to recover any specific Digital Assets, rather you
            will have the right to receive the same quantity and same type of
            Digital Assets as those credited to your Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.13.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Force Majeure</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            We will not be liable for any delay or failure to perform as
            required by these Terms to the extent the delay or failure is caused
            by a Force Majeure Event.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.14.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>No waiver</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            No delay or omission by us in exercising any right or remedy under
            the Terms will operate as a waiver of the future exercise of that
            right or remedy or of any other rights or remedies under the Terms.
            The rights and remedies provided in the Terms are cumulative and not
            exclusive of any rights or remedies provided by Applicable Law.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.15.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Set-off</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            In addition to any legal or other remedy available under the Terms
            or by law, we may set-off any amounts you owe to us under the Terms
            or otherwise. You must pay all sums that you owe to us free and
            clear without any set-off, counterclaim, deduction or withholding of
            any kind, save as may be required by Applicable Law.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.16.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Privacy of others</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . If you receive information about another user through the Platform
            or from utilising the Indexx Services, you must keep the information
            confidential and only use it in connection with the Indexx Services
            and always in accordance with Applicable Law. You must not disclose
            or distribute any user information to a third party or use the
            information in any manner except as reasonably necessary to effect a
            Transaction.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.17.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Publication of breaches.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            Where you have breached these Terms, Indexx may publish, or
            otherwise provide its users with details of the breach, including
            any information that you have provided Indexx. Indexx may only do so
            where it determines that doing so is necessary for the protection of
            other users, and consistent with Applicable Law.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.18.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Death of Indexx Account holder</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . In the event of your death or incapacity, the representative(s) or
            legal beneficiary/beneficiaries of your estate may give us written
            notice. If we have reason to believe you have died, we may suspend
            your Indexx Account. Your Indexx Account will be suspended
            until:&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a representative of your estate or authorised beneficiary completes
            a successful inheritance application to receive the assets in your
            Indexx Account in accordance with our requirements which may be
            amended from time to time without notice; ; or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            you provide satisfactory proof that you are not deceased.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Our ability to provide your representative(s) with the assets in
            your Indexx Account is subject to the restrictions imposed by
            Applicable Law and these Terms. We do not commit to any particular
            timeline for the transfer of assets held to the credit of your
            Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            36.19.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Tax</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            . It is your responsibility to determine what, if any, taxes apply
            to the payments you make or receive, and it is your responsibility
            to collect, report, and remit the correct tax to the appropriate tax
            authority. You agree that we are not responsible for determining
            whether any taxes apply to your use of the Indexx Services, or for
            collecting, reporting or remitting any taxes arising from any
            Transaction or use of the Indexx Services.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            You acknowledge that we may make certain reports to tax authorities
            regarding Transactions made on the Platform, and that we may, in our
            sole discretion or as required by Applicable Law, provide you with
            additional documentation or records needed by you to calculate any
            tax obligations. We may also withhold and deduct at source any taxes
            due under Applicable Law in our sole discretion.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>37.</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Definitions and interpretation</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            In these Terms:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            clause headings and numbering are for convenience only and do not
            affect the meaning, priority or interpretation of any clause or
            sub-clause of these Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the words “include” or “including” shall mean including without
            limitation and include without limitation respectively;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any undertaking to do or not do a thing shall be deemed to include
            an undertaking not to permit or suffer the doing of that act or
            thing;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            words importing the singular include the plural and vice versa and
            words importing a gender include any gender;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any reference to a document is to that document as amended, varied
            or novated from time to time otherwise than in breach of these Terms
            or that document; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in the event of inconsistency between these Terms (including any
            documents referred to in these Terms) the inconsistency shall be
            solved by giving such provisions and documents the following order
            of precedence:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the Product Terms;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            Privacy Notice
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ; and
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            iii.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            g.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            except where the context requires others, the following terms shall
            have the following meanings:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Access IDs </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means your Indexx Account details, username, passwords, personal
            identification numbers, API keys, API secret keys, or any other
            codes or forms of authentication that you use to access your Indexx
            Account or the Indexx Services or to send Instructions.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Account History</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the written record (including electronic records) of your
            Transactions and your Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Airdrop means the attempted distribution or distribution by a
            Digital Asset network of any Digital Asset to Digital Asset
            addresses of a supported network.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>API</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means application program interface.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Applicable Law </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means all relevant or applicable statutes, laws (including rules of
            common law), principles of equity, rules, regulations, regulatory
            principles and requirements, notices, orders, writs, injunctions,
            judgements, bye-laws, rulings, directives, proclamations, circulars,
            mandatory codes of conduct, guidelines, practice notes and
            interpretations (whether of a governmental body, regulatory or other
            authority, or self-regulatory organisation of which Indexx is a
            member), that are applicable to the provision, receipt or use of the
            Indexx Services, or any other products or deliverables provided,
            used or received in connection with the Indexx Services, these
            Terms, or any Product Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Backed Digital Assets </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            has the meaning given to it in clause ‎15.3.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ,{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>we</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            ,{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>our</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            or{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>us</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means Nest Services Limited (a company incorporated under the laws
            of the Republic of Seychelles with registration number 238045 and
            address at House of Francis, Room 303, Ile Du Port, Mahe,
            Seychelles).
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx Account</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means any accounts (including Corporate Indexx Accounts) or
            sub-accounts (including any Indexx Sub-Account), which are opened by
            Indexx for you to record your use of Indexx Services.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx Affiliates </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means with respect to Nest Services Limited: any other person which,
            directly or indirectly, Controls, is Controlled by, or is under
            common Control with, Nest Services Limited.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx API</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means an API made available by a Indexx Affiliate to you as a
            service, or third-party applications relying on such an API.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx IP</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the Created IP and all other Intellectual Property Rights
            owned by or licensed, on a sub-licenseable basis, to us as at the
            date of the Terms and any other Intellectual Property Rights owned
            or acquired by or licensed, on a sub-licenseable basis, to us after
            the date of these Terms, and which are provided by us to you in the
            course of providing you with the Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx Services</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the services offered by us to you through the Platform.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Indexx Sub-Account</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means a sub-account that is set up under a primary Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Chat Service </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            has the meaning given to it in clause ‎5.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Claim</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means any dispute, claim, difference or controversy between you and
            Indexx (and/or any Indexx Affiliates) arising out of, in connection
            with, or relating in any way to:&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (a)
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            these Terms or any Product Terms, including their existence,
            validity, subject matter, interpretation, performance, breach,
            negotiation, termination, enforceability or the consequences of
            their nullity;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (b)
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your relationship with Indexx (and/or any Indexx Affiliates) as a
            user (whether based in contract, tort, statute, fraud,
            misrepresentation, or any other legal theory, and whether the claims
            arise during or after the termination of these Terms); or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (c)
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any non-contractual obligations arising out of or relating to these
            Terms, or any Product Terms, or your relationship with Indexx
            (and/or any Indexx Affiliates).
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Control</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the power of a person to secure that the affairs of another
            are conducted in accordance with the wishes of the first person
            whether by means of:
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (a)
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in the case of a company, being the beneficial owner of more than
            fifty percent (50%) of the issued share capital of or of the voting
            rights in that company, or having the right to appoint and remove a
            majority of the directors or otherwise control the votes at board
            meetings of that company by virtue of any powers conferred by the
            organisational documents, shareholders’ agreement, a majority of the
            board of directors or any other document regulating the affairs of
            that company or by any other means; or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            (b)
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            in the case of a partnership, being the beneficial owner of more
            than fifty percent (50%) of the capital of that partnership, or
            having the right to control the composition of or the votes to the
            majority of the management of that partnership by virtue of any
            powers conferred by the partnership agreement or any other document
            regulating the affairs of that partnership or by any other means.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Corporate Indexx Account</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means a Indexx Account maintained for a corporation, entity, or
            other organisation for the provision of Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Created IP</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means any Intellectual Property Rights created by you pursuant to
            these Terms, including the User Materials, but excluding any other
            User IP.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Digital Assets</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means a digital representation of value or rights which may be
            transferred and stored electronically, using distributed ledger
            technology or similar technology, including, but not limited to,
            cryptocurrencies, stablecoins, non-fungible tokens and tokenised
            derivatives of any other digital asset.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Dominant Digital Asset </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means a Forked Digital Asset that has dominance over one or more
            other versions of a Digital Asset that was subject to the relevant
            Fork, as determined by us in our sole discretion.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Email</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Account</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the email account(s) associated with your Indexx Account(s),
            as agreed with Indexx from time to time, in accordance with any
            processes identified by Indexx when using the Platform.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Fiat Currency </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means any government or central bank issued national, or
            supra-national, currency, or other monetary obligation denominated
            in such currency and which is not a Digital Asset.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Force Majeure Events </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means:&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any fire, strike, riot, civil unrest, terrorist act, war or
            industrial action;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any natural disaster such as floods, tornadoes, earthquakes and
            hurricanes;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any epidemic, pandemic or public health emergency of national or
            international concern;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            d.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any act or regulation made by a government, supra national body or
            authority that we believe stops us from providing Indexx Services on
            the Platform;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            e.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the suspension or closure of any Indexx Affiliate;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            f.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the nationalisation of any Indexx Affiliate;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            g.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the imposition of limits or unusual terms by a government on any
            Digital Assets traded on our Platform;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            h.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            technical failures in transmission, communication or computer
            facilities including power failures and electronic or equipment
            failures;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            i.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the failure of any supplier, intermediate broker, agent, principal
            custodian, sub-custodian, dealer, exchange, staking platform,
            liquidity pool, bridge provider, issuer of a Backed Digital Asset,
            market maker, clearing house or regulatory organisation to perform
            its obligations to us;&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            j.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any labour or trade disputes, strikes, industrial actions or
            lockouts (other than in each case by Indexx or the Indexx
            Affiliates); and/or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            k.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            an event which significantly disrupts the market for Digital Assets,
            which could include excessive movements in the price, supply or
            demand of a Digital Asset, whether regulated or unregulated.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Fork </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong> </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any planned, unplanned, sudden, scheduled, expected, unexpected,
            publicised, not well-known, consensual, and/or controversial changes
            to the underlying operating rules of certain Digital Assets that may
            occur from time to time, in such a way as to result in the creation
            of one or more related versions of an existing Digital Asset.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Forked Digital Asset </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means a Digital Asset that results from a Fork.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Improper Intent</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means Indexx reasonably determines that there may be actual or
            suspected market manipulation and market abuse on your part,
            including (without limitation), capitalising on opportunities where
            the executable price of a Transaction does not reflect prevailing
            market rates, or taking unfair advantage of the way in which Indexx
            offers prices.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Instruction</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means any instruction, request, or order given to Indexx by you or a
            Permitted User in relation to the operation of your Indexx Account
            or to execute any Transaction, through such medium and in such form
            and manner as Indexx may require and “Instruct” shall be construed
            accordingly.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Intellectual Property Rights</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means: (a) copyright, patents, database rights and rights in trade
            marks, designs, know-how and confidential information (whether
            registered or unregistered); (b)&nbsp;applications for registration,
            and rights to apply for registration, of any of the foregoing
            rights; and (c) all other intellectual property rights and
            equivalent or similar forms of protection existing anywhere in the
            world.
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Local Terms</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the terms governing your use of the Indexx Services provided
            in any particular jurisdiction.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Losses</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            has the meaning given to it in clause{" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>‎</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            29.1.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Manifest Error </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means any error, omission or misquote (whether an error of Indexx or
            any third party) which is manifest or palpable, including a misquote
            by any representative of Indexx taking into account the current
            market and currently advertised quotes, or any error of any
            information, source, official, official result or pronunciation.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Mobile App </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means any mobile application developed or provided by us or any of
            the Indexx Affiliates that enables you to use or otherwise access
            Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Network Event means in relation to a Digital Asset, any event (other
            than an Airdrop or Fork) in respect of the blockchain or the smart
            contract that underlies a Digital Asset, which is beyond Indexx’s
            control, and results in either (a) a loss of control or ownership by
            Indexx or a third party of any amount of such Digital Asset; or (b)
            transaction records on the blockchain being altered, reversed or
            otherwise invalidated, whether by way of a fraudulent act or
            consensus, which shall include without limitation any double
            spending attack, 51-percent attack, or blockchain reorganization, in
            each case as determined by Indexx in good faith and in its sole
            discretion.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Permitted User</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means any person identified by you and communicated to us, in
            accordance with these Terms, that is authorised to act on a user’s
            behalf with respect to any Corporate Indexx Account, or with our
            prior written consent, on behalf of a third party with respect to a
            Indexx Sub-Account associated with a Corporate Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Platform</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the digital platform that we or any of the Indexx Affiliates
            may make accessible to you via Sites, the Mobile App, a Indexx API
            or by such other means as Indexx Affiliates may prescribe from time
            to time for the use of Indexx Services.
          </span>
          <br />
          <br />
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Privacy Notice </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means the privacy notice located at{" "}
          </span>
          <a
            data-bn-type="link"
            href="https://www.indexx.ai/privacy-policy"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            https://www.indexx.ai/privacy-policy
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Product Terms</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the product-specific terms and conditions that apply to the
            use of a Indexx Service, in addition to these Terms.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Regulatory Authority</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means any foreign, domestic, state, federal, cantonal, municipal or
            local governmental, executive, legislative, judicial,
            administrative, supervisory or regulatory authority, agency,
            quasi-governmental authority, court, commission, government
            organisation, self-regulatory organisation having regulatory
            authority, tribunal, arbitration tribunal or panel or supra-national
            organisation, or any division or instrumentality thereof, including
            any tax authority.
          </span>
        </p>

        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Risk Warning</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the general risk warning published on the Website.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Security Breach</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            a.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            your Indexx Account(s) or any of your Access IDs have been
            compromised;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            b.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            the loss, theft, or unauthorised use of any of your Access IDs or
            any unauthorised access to and use of your Indexx Account or the
            Indexx Services on your behalf; or
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            c.
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            any other security incident (including a cyber-security attack)
            affecting you and/or Indexx.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Sites</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means our Website and any other websites, pages, features, or
            content we own or operate.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Supported Digital Assets</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means those Digital Assets that are available in connection with
            your use of the Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Terms</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means these terms of use, together with any other documents
            expressly incorporated by reference, including the Product Terms, in
            each case as amended or supplemented from time to time.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Trade Marks</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the Intellectual Property Rights in the trade marks, service
            marks and logos used and displayed on or through the Platform, the
            Sites and/or the Indexx Services.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Transaction</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means selling, purchasing, or entering into any other type of
            transactions, or agreeing to sell, purchase or enter into any other
            type of transactions involving Digital Asset(s), their derivatives,
            other asset(s) or product(s) as Indexx may from time to time permit
            to be carried out on the Platform, and transferring Digital Assets
            or Fiat Currency into or out of your Indexx Account.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>User IP </strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            means the Intellectual Property Rights owned by or licensed to you
            as at the date of these Terms and any other Intellectual Property
            Rights owned or acquired by or licensed to you after the date of
            these Terms, excluding Indexx IP.&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>User Materials</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the Intellectual Property Rights in any reviews, posts,
            information, data, and comments you or other users provide to us on
            the Sites (through our “Contact Us” pages or otherwise), the
            Platform, through use of Indexx Services, or otherwise.
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Website</strong>
          </span>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            {" "}
            means the website located at{" "}
          </span>
          <a
            data-bn-type="link"
            href="http://www.indexx.ai"
            target="_blank"
            className="richtext-link iris-ej0fv9"
          >
            www.indexx.ai
          </a>
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            .&nbsp;
          </span>
        </p>
        <p className=" richtext-paragraph richtext-paragraph-empty iris-127w7vq" />
        <p className=" richtext-paragraph  css-zwb0rk richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            <strong>Product Terms</strong>
          </span>
        </p>
        <p className=" richtext-paragraph  iris-127w7vq">
          <span data-bn-type="text" className="richtext-text iris-6hm6tl">
            Please note, some products may not be available in your location.
          </span>
        </p>
        <div className="iris-114qm07">
          <div className="iris-140j5nz">
            <div className="bn-table bn-table--border hidden-table-header">
              <div className="bn-table-container">
                <div className="bn-table-content">
                  <table style={{ tableLayout: "auto" }}>
                    <colgroup />
                    <tbody className="bn-table-tbody">
                      <tr
                        data-row-key={0}
                        className="bn-table-row bn-table-row-level-0"
                      ></tr>
                      <tr
                        data-row-key={1}
                        className="bn-table-row bn-table-row-level-0"
                      >
                        <td className="bn-table-cell">
                          <div className="iris-1yx6mni">
                            <div className="iris-u1wi5n">
                              <p className=" richtext-paragraph  iris-127w7vq">
                                <a
                                  data-bn-type="link"
                                  href="https://www.indexx.ai"
                                  target="_blank"
                                  className="richtext-link iris-ej0fv9"
                                  data-app-url="https://cex.indexx.ai/convert"
                                >
                                  Convert
                                </a>
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-row-key={2}
                        className="bn-table-row bn-table-row-level-0"
                      >
                        <td className="bn-table-cell">
                          <div className="iris-1yx6mni">
                            <div className="iris-u1wi5n">
                              <p className=" richtext-paragraph  iris-127w7vq">
                                <a
                                  data-bn-type="link"
                                  href="https://www.indexx.ai/indexx-exchange/buy-sell/staking"
                                  target="_blank"
                                  className="richtext-link iris-ej0fv9"
                                >
                                  DeFi Staking
                                </a>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="bn-table-cell">
                          <div className="iris-1yx6mni">
                            <div className="iris-u1wi5n">
                              <p className=" richtext-paragraph  iris-127w7vq">
                                <a
                                  data-bn-type="link"
                                  href="https://www.indexx.ai/indexx-exchange/buy-sell/staking"
                                  target="_blank"
                                  className="richtext-link iris-ej0fv9"
                                >
                                  ETH Staking
                                </a>
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-row-key={3}
                        className="bn-table-row bn-table-row-level-0"
                      >
                        <td className="bn-table-cell">
                          <div className="iris-1yx6mni">
                            <div className="iris-u1wi5n">
                              <p className=" richtext-paragraph  iris-127w7vq">
                                <a
                                  data-bn-type="link"
                                  href="https://www.indexx.ai/redeem/create-card"
                                  target="_blank"
                                  className="richtext-link iris-ej0fv9"
                                >
                                  Gift Card
                                </a>
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        data-row-key={4}
                        className="bn-table-row bn-table-row-level-0"
                      >
                        <td className="bn-table-cell">
                          <div className="iris-1yx6mni">
                            <div className="iris-u1wi5n">
                              <p className=" richtext-paragraph  iris-127w7vq">
                                <a
                                  data-bn-type="link"
                                  href="https://www.indexx.ai/indexx-exchange/buy-sell/staking"
                                  target="_blank"
                                  className="richtext-link iris-ej0fv9"
                                >
                                  NFT Staking
                                </a>
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className=" richtext-paragraph richtext-paragraph-empty iris-127w7vq" />
      </div>
    </div>
  );
};

export default TermAndConditionPage;
