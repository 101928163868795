import { useTheme } from "@mui/material/styles";
import React from "react";
import LoginPasswordComponent from "../../../components/updated/authentication/loginPassword";
import Popup from "../../../components/updated/shared/Popup";
import { useLocation } from "react-router-dom";
import johnLight from "../../../assets/updated/auth/John Light mode.svg";
import johnDark from "../../../assets/updated/auth/John Dark Mode.svg";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme: any) => ({
  Container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme?.palette?.background.default,
    color: theme?.palette?.text?.primary,
    marginTop: "50px",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  orderBy: {
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
}));

const Login = () => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const email = location.state?.email || localStorage.getItem("email") || "";
  const redirectWebsiteLink = location.state?.redirectWebsiteLink;
  const referral = location.state?.referral;
  const userType = location.state?.userType;

  console.log("I am in login password location.state", referral);
  console.log("I am here", email);
  return (
    <div className={classes.Container}>
      {referral && referral === "zsuitepay" && (
        <div className={classes.orderBy} style={{ flex: 1 }}>
          <img
            src={theme.palette.mode === "dark" ? johnDark : johnLight}
            alt=""
            width={"95%"}
          />
        </div>
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoginPasswordComponent
          email={email}
          redirectWebsiteLink={redirectWebsiteLink}
          userType={userType}
        />
      </div>

      {/* <Popup /> */}
    </div>
  );
};

export default Login;
