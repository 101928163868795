import { useTheme } from "@mui/styles";
import { NavLink } from "react-router-dom";

const LeftNav = () => {
  const theme: any = useTheme();
  console.log(theme, "theme");
  return (
    <div className="lef_nav_container d-md-block d-none">
      <div className="nav_Section">
        <ul>
          <li>
            <NavLink
              to="/indexx-exchange/help"
              className=""
              style={{
                color: theme?.palette?.mode === "dark" ? "white" : "black",
              }}
              end
            >
              Indexx Intro
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/indexx-exchange/help/team"
              style={{
                color: theme?.palette?.mode === "dark" ? "white" : "black",
              }}
            >
              Indexx Team
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink
              to="/indexx-exchange/help/contact"
              style={{
                color: theme?.palette?.mode === "dark" ? "white" : "black",
              }}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LeftNav;
