import legal1 from "../../assets/legal/kml.png";

const Amlkmc = () => {
  return (
    <div className="help_content_page" style={{ height: "max-content" }}>
      <div className="nav_main_header border-b d-md-block d-none">
        <p style={{ fontWeight: 400, fontSize: 30 }}>
          {" "}
          <img src={legal1} alt={""}></img> AML/KYC{" "}
        </p>
      </div>
      <div className="trade_content nav_section border-b">
        {/* <div className="section_header d-flex">
                    <h1><img src={tradeIcon} alt="trade icon" />Trade</h1>
                </div> */}

        <p style={{ fontSize: 20, lineHeight: 2 }}>
          <b>
            These AML/KYC Terms were last updated on the 2nd of January 2023.
          </b>
          <br />
          <br />
          These KYC Terms constitute an integral part of indexx.ai’s Terms of
          Service.
          <br />
          References in this AML/KYC Terms (the “Terms”) to “Indexx.ai”,
          “Service”, “Application”, “App” relate to the website, available at
          https://indexx.ai, operated by Indexx.ai Ltd. (The correspondent
          address of the Company shall be at the offices of Irvine Technology
          Center Irvine, CA 92618, California)
          <br />
          <br />
          1. General provisions
          <br />
          <br />
          These Policies of procedure for prevention of money laundering and
          terrorist financing, and compliance with international sanctions lay
          down requirements for screening the Clients in order to prevent
          entering into deals involving suspected money laundering and terrorist
          financing, and to ensure identification and reporting of such in case
          of existence of a risk determined by the Company. These AML/KYC
          Policies shall be applied in case of any issue related with money or
          financial transaction(s) is occurred.
          <br />
          The Rules are primarily based on the regulations of AML/KYC
          legislation applicable and international sanctions acts.
          <br />
          <br />
          2. Application of due diligence measures
          <br />
          <br />
          The Company shall determine and take due diligence measures using
          results of conducted international risk assessment and provisions of
          national risk assessment.
          <br />
          The Representatives shall pay special attention to circumstances that
          refer to Money Laundering or Terrorist Financing.
          <br />
          Depending on the level of the risk of the Client and depending on the
          fact whether the business relationship is an existing one or it is
          about to be established, the Company shall apply either normal KYC/AML
          measures, simplified KYC/AML measures or enhanced KYC/AML measures.
          The Company may also apply continuous KYC/AML measures to ensure
          ongoing monitoring of business relationships.
          <br />
          KYC/AML measures may include, but are not limited with, the following
          procedures:
          <br />
          o Identifying the Client and verifying its identity using reliable,
          independent sources, documents or data, including e-identifying;
          <br />
          o Identifying and verifying of the representative of the Client and
          the right of representation;
          <br />
          o Identifying the Client's Beneficial Owner;
          <br />
          o Assessing and, as appropriate, obtaining information on the purpose
          of the business relationship;
          <br />
          o Conducting ongoing KYC/AML on the Client's business to ensure the
          Company’s knowledge of the Client and its source of funds is
          <br />
          correct;
          <br />
          o Obtaining information whether the Client is a PEP or PEP’s family
          member or PEP’s close associate;
          <br />
          o Obtaining information from any third party, e.g. government
          authorities.
          <br />
          The Company may establish the source of wealth of the Client, where
          appropriate.
          <br />
          To comply with the KYC/AML obligation, the Company or any third party
          appointed by the Company shall have the right and obligation to:
          <br />
          o request appropriate identity documents to identify the Client and
          its representatives;
          <br />
          o request documents and information regarding the activities of the
          Client and legal origin of funds;
          <br />
          o request information about beneficial owners of a legal person;
          <br />
          o screen the risk profile of the Client, select the appropriate
          KYC/AML measures, assess the risk whether the Client is or may become
          <br />
          involved in money laundering or terrorist financing;
          <br />
          o re-identify the Client or the representative of the Client, if there
          are any doubts regarding the correctness of the information received
          <br />
          in the course of initial identification.
          <br />
          The objective of the continuously applied KYC/AML measures is to
          ensure on-going monitoring of Clients. Conducting ongoing monitoring
          of the Business Relationship includes:
          <br />
          o Keeping up-to-date the documents, data or information, obtained
          during taking KYC/AML measures;
          <br />
          o Paying particular attention to Client’s actions, leading to criminal
          activity or money laundering or terrorist financing;
          <br />
          o Paying particular attention to Client’s actions, leading to criminal
          activity or money laundering or terrorist financing;
          <br />
          The Company evaluates the substance and the purpose of the Client’s
          activities, in order to establish the possible links with money
          laundering or terrorist financing. The evaluation should result in an
          understanding of the purpose of the business relationship for the
          Client, the nature of the Client’s business, the risk levels of the
          Client and, if necessary, the sources of funds.
          <br />
          <br />
          3. Identification of a person
          <br />
          <br />
          Upon implementing KYC/AML measures the following person may be
          identified upon the Company’s sole discretion:
          <br />
          o Client – a natural or legal person;
          <br />
          o In case of a legal person, all relevant statutory documentation
          shall be presented upon the Company’s request;
          <br />
          o Representative of the Client – an individual who is authorized to
          act on behalf of the Client;
          <br />
          o Beneficial owner of the Client;
          <br />
          o PEP – if the PEP is the Client or a person connected with the
          Client;
          <br />
          o Source of wealth and source of funds;
          <br />
          o Any other information the Company seems to be sufficient, e.g.
          supporting documents from the government authorities.
          <br />
          Documents that can be used for identification
          <br />
          o In case of Clients being natural persons and the representatives of
          Clients, the following documents can be used for identification:
          <br />
          Personal ID card (whether ID card, e-resident card or residence permit
          card);
          <br />
          Passport or diplomatic passport;
          <br />
          Travel document issued in a foreign country;
          <br />
          o The Representative shall make a copy of the page of identity
          document which contains personal data and photo.
          <br />
          o In addition to an identity document, the representative of a Client
          shall submit a document in the required format certifying the right
          <br />
          of representation.
          <br />
          o Legal person and its passive legal capacity shall be identified and
          verified on the basis of the following documents:
          <br />
          in case of legal persons registered in England and Wales and branches
          of foreign companies registered in England and Wales, the
          identification shall be conducted on the basis of an extract of a
          registry card of commercial register;
          <br />
          foreign legal persons shall be identified on the basis of an extract
          of the relevant register or a transcript of the registration
          certificate or an equal document, which has been issued by a competent
          authority or body not earlier than six months before submission
          thereof.
          <br />
          <br />
          4. Risk assessment and action steps
          <br />
          <br />
          The Representative will establish a risk profile of a Client based on
          information gathered under the Rules.
          <br />
          The Company applies the following risk categories:
          <br />
          o Normal risk;
          <br />
          o Medium Risk
          <br />
          o High risk.
          <br />
          The Company is entitled to apply any combination of AML/KYC procedures
          based on a risk category of a Client and on a risk-based approach to
          each Client of the Company.
          <br />
          In accordance with the applicable laws and the requirements of
          international organizations, the Company may, where appropriate and
          without the obligation of obtaining the Client’s approval or notifying
          the Client, notify regulating and/or law enforcement agencies of any
          suspicious transactions.
          <br />
          The Company shall periodically refer to and consult the lists
          published by the local authorities and international organizations
          that contain lists of known terrorists or persons suspected of
          terrorist activities, terrorist organizations, high-risk countries, a
          limited list of countries subject to the OFAC sanctions, jurisdictions
          that do not provide sufficient level of anti-money laundering
          procedures, as well as countries subject to sanctions to determine
          whether the Company’s Client, and/or such Client’s country of
          jurisdiction is included in the above lists.
          <br />
          <br />
          5. Client’s covenants
          <br />
          <br />
          The Client hereby certifies that they have read and understood this
          Policy, and the Client shall operate in full compliance with the
          requirements and standards outlined in the Policy and comply with all
          applicable laws and other regulations and requirements governing its
          activities as a Client of the Company.
          <br />
          The Client acknowledges that they are responsible for their actions in
          accordance with the effective laws in the field discussed in this
          Policy and shall bear responsibility pertaining to failure to comply
          with such laws.
        </p>
      </div>
    </div>
  );
};

export default Amlkmc;
