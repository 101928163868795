import React, { useContext } from "react";
import freeTicket from "../../assets/homepage/iusdup.svg";
import Promo2 from "../../assets/homepage/promo2.png";
import btcIcon from "../../assets/homepage/btc_ai_icon.svg";
// import wis from "../../assets/homepage/wis_banner.svg";
import wisImg from "../../assets/popup/14JulyImg.png";
import wisText from "../../assets/popup/hot-summer.svg";
import indexxShop from "../../assets/popup/daCrazySales.svg";
import flower from "../../assets/homepage/flower.png";
import btcText from "../../assets/homepage/btc_popup_text.svg";
import indexxLogo from "../../assets/homepage/indexx_logo_white.svg";
import inexLogo from "../../assets/homepage/iusddown.svg";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../../assets/homepage/updated/cross_white.png";
import { Popup } from "../../utils/PopupContest";
const Modal = ({ show, onClose }) => {
  let navigate = useNavigate();
  const { setPopup } = useContext(Popup);

  // const firstPromoHandler = () => {
  //   window.location.href =
  //     "https://lotto.indexx.ai/contest-details?uniqueCode=WFE500";
  // };

  const firstPromoHandler = () => {
    const expiration = new Date().getTime() + 30 * 60 * 1000; // 30 minutes from now
    localStorage.setItem("expirationTime", String(expiration));
    setPopup(false);
    onClose();
    navigate("/airdrop-dacrazy");
    // window.location.href = "https://cex.indexx.ai/update/home?buyToken=DaCrazy";
  };
  const closePopupHandler = () => {
    const expiration = new Date().getTime() + 30 * 60 * 1000; // 30 minutes from now
    localStorage.setItem("expirationTime", String(expiration));
    setPopup(false);
    onClose();
  };

  return (
    <>
      {show && (
        <div className="modal-backdrop" onClick={closePopupHandler}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div
              className="modal-body"
              style={{
                width: "100%",
                maxWidth: "800px",
                margin: "auto",
                position: "relative",
              }}
            >
              <div className="modals-container text-center">
                <button
                  className="close-btn-modl"
                  onClick={() => closePopupHandler()}
                >
                  <img src={CrossIcon} />
                </button>
                {/* <div className="modals-item" style={{ background: "#D9FAFF" }}>
                  <button
                    className="close-btn-modl"
                    onClick={() => onClose(false)}
                  >
                    X
                  </button>

                  <div style={{ background: "#0155EE", padding: "5px 0px" }}>
                    <img src={indexxLogo} alt="Image 1" height={42} />
                  </div>
                  <div>
                    <img src={freeTicket} alt="Image 1" width="90%" />
                  </div>
                  <div>
                    <img src={inexLogo} alt="Image 1" />
                  </div>
                  <br />
                  <br />

                  <button
                    onClick={firstPromoHandler}
                    className="modals-button"
                    style={{ background: "#004DCD", borderRadius: "2px" }}
                  >
                    Participate Now
                  </button>
                </div> */}
                {/* <div className="modals-item" style={{ background: "#000" }}>
                  <div style={{ background: "#0155EE", padding: "22px 0px" }}>
                    <img src={indexxLogo} alt="Image 1" height={42} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "50px 10px",
                    }}
                  >
                    <div>
                      <img src={btcIcon} alt="Image 1" width="90%" />
                    </div>
                    <div>
                      <img src={btcText} alt="Image 1" />
                    </div>
                  </div>

                  <br />
                  <br />

                  <button
                    onClick={secondPromoHandler}
                    className="modals-button"
                    style={{ background: "#F59219", borderRadius: "2px" }}
                  >
                    Participate Now
                  </button>
                </div> */}
                <div
                  className="modals-item"
                  style={{
                    background: "#FDF8F0",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div style={{ background: "#0155EE", padding: "9.5px 0px" }}>
                    {/* <img src={indexxLogo} alt="Image 1" height={42} /> */}
                  </div>
                  {/* <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "160px",
                      zIndex: "0",
                    }}
                  >
                    <img src={flower} style={{ width: "100%" }} />
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "50px auto -20px auto",
                        // marginBottom: "100px",
                      }}
                    >
                      <img src={indexxShop} alt="Image 1" width={"90%"} />
                    </div>
                  </div>

                  <button
                    onClick={firstPromoHandler}
                    className="modals-button"
                    style={{
                      borderRadius: "2px",
                      position: "relative",
                      color: "#FFF",
                      fontSize: "14px",
                      marginTop: "40px",
                      zIndex: 111,
                    }}
                  >
                    Claim Now
                  </button>
                </div>
                {/* <div className="modals-item" style={{ background: "black" }}>
                  <div style={{ height: "400px" }}>
                    <img
                      src={Promo2}
                      alt="Image 2"
                      className="modals-image"
                      style={{ paddingBottom: "20px" }}
                    />
                  </div>
                  <button
                    onClick={secPromoHandler}
                    className="modals-button"
                    style={{ background: "#f59219", borderRadius: "2px" }}
                  >
                    Participate Now
                  </button>
                </div> */}
                {/* <div className="modals-item" style={{ background: "#F7F0FD" }}>
                  <div style={{ height: "400px" }}>
                    <img src={Promo3} alt="Image 3" className="modals-image" />
                  </div>
                  <button
                    onClick={thirdPromoHandler}
                    className="modals-button-blue"
                  >
                    Participate Now
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
